import patients_icon from '../../media/icons/patients.svg';
import doctors_icons from '../../media/icons/doctors.svg';
import chart_icon from '../../media/icons/charts.svg';
import document_icon from '../../media/icons/document2.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addValue } from '../../actions/index.js';
import { t } from '../../config/functions/translate';

function Widget(props) {

    const widget = props.widget;

    const counters = props.counters;

    const index = props.index;

    const color = widget.value < widget.target ? ' black' : ' rgb(0, 103, 0)';

    return(
        <>
        <div className="flex" style={{boxSizing: 'border-box', paddingLeft: '30px', paddingRight: '25px', width: '100%', gridColumn: 'span 1'}}>
            <div className="flex user-select" style={{width: '40%', height: '100%', alignItems: 'end'}}>
                <img src={widget.icon} draggable={false} style={{width: '65%', height: '40%', opacity: widget.opacity}}></img>
            </div>
            <div className="flex-column" style={{width: '60%', height: '100%'}}>
                <div className="flex" style={{height: '70%', justifyContent: 'end', alignItems: 'flex-end'}}>
                    <div className="flex-column" style={{width: '100%', height: '100%', alignItems: 'end', gap: '2px'}}>
                        <h6 className="bold"  style={{opacity: '0.45'}}>{widget.date}</h6>
                        <h2 style={{fontSize: '20px', lineHeight: '23px', fontWeight: '500'}}>{t(widget.title)}</h2>
                    </div>
                </div>
                <div className="flex-column" style={{height: '30%', justifyContent: 'end', alignItems: 'flex-end', gap: '2px'}}>
                    <h6 className="bold" style={{opacity: '0.45'}}>{t(widget.description)}</h6>
                    <h2 style={{fontSize: '19.5px', lineHeight: '24px', color: color}}>{widget.value}</h2>
                </div>
            </div>
        </div>

        {
            counters.length !== (index + 1) &&
                <div className="flex-column x-a" style={{width: 'auto', height: '100%'}}>
                    <div style={{width: '1px', height: '100%', backgroundColor: 'var(--border--color)'}}>
                    </div>
                </div>
        }
        </>
    )
}


export default function Overview() {

    const state = useSelector(state => state.home);

    const dispatch = useDispatch();

    useEffect(() => {


        
        const url = 'https://api.sonardigital.it/format/format.php?api=totalepazienti';

        if (state?.physicians === undefined) {

            const body = new FormData();

            body.append('token', localStorage.getItem('token'));

            fetch(fetch(url, {
                method: 'POST',
                heders: {
                'Accept': 'application/json',
                'Content-type':'application/json'
                },
                body: body
                }).then(response => {
                return response.json();
                }).then(data => {
                    dispatch(addValue('physicians', data.data.value));
                }).catch(error => {
                // console.log("Error: " + error);
            }))
        }


    }, [])

    useEffect(() => {
        
        const url = 'https://api.sonardigital.it/format/format.php?api=totalemedici';

        if (state?.physicians === undefined) {

            const body = new FormData();

            body.append('token', localStorage.getItem('token'));

            fetch(fetch(url, {
                method: 'POST',
                heders: {
                'Accept': 'application/json',
                'Content-type':'application/json'
                },
                body: body
                }).then(response => {
                return response.json();
                }).then(data => {
                    dispatch(addValue('patients', data.data.value));
                }).catch(error => {
                // console.log("Error: " + error);
            }))
        }

    }, [])

    useEffect(() => {
        
        const url = 'https://api.sonardigital.it/format/format.php?api=totalegrafici';

        if (state?.charts === undefined) {
            dispatch(addValue('charts', '56'));

            const body = new FormData();

            body.append('token', localStorage.getItem('token'));

            fetch(fetch(url, {
                method: 'POST',
                heders: {
                'Accept': 'application/json',
                'Content-type':'application/json'
                },
                body: body
                }).then(response => {
                return response.json();
                }).then(data => {
                    dispatch(addValue('charts', '42'));
                }).catch(error => {
                // console.log("Error: " + error);
            }))
        }

    }, [])

    const counters = [
        {id: '', title: 'Patients', icon: patients_icon,  opacity: '0.42', value: state?.physicians, target: 100, description: 'Total', date: '29/05/2023'},
        {id: '', title: 'Physicians', icon: doctors_icons, opacity: '0.35',  value: state?.patients, target: 30, description: 'Total', date: '29/05/2023'},
        {id: '', title: 'Charts', icon: chart_icon, opacity: '1',  value: state?.charts, target: 30, description: 'Total', date: '29/05/2023'},
        {id: '', title: 'Waves', icon: document_icon, opacity: '0.45',  value: 1, target: 1, description: 'Total', date: '29/05/2023'}
    ];

    return(
        <div style={{gridColumn: 'span 10', height: '150px', backgroundColor: '#F7F7F7', borderRadius: 'var(--widget--radius)'}}>
            <div className="flex" style={{boxSizing: 'border-box', padding: '20px', dispaly: 'grid', gridAutoRows: 'min-content', gridTemplateColumns: 'repeat(4, 1fr)', width: '100%', height: '100%'}}>
                {
                    counters.map((counter, index) => {
                        return(
                            <Widget 
                            key={index}
                            widget={counter}
                            counters={counters}
                            index={index}/>
                        )
                    })
                }
            </div>
        </div>
    )
}