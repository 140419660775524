import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { saveData } from "../../actions";

import { saveChartData } from "../../actions/pages";

function addFilter(body, field, value) {

  body.append(field, value);

}

export function useFetch(chart, page) {

  const globalFilters = useSelector(state => state.globalfilters);
  // const globalFilters = {regione: ['8&9&12&17']}

  // chart.filters = {regione: ['8&9&12&17']}

  const chart_filters = chart?.filters === undefined ? {} : chart.filters; 

  // const filters = (chart?.filters !== undefined ? chart?.filters : {});

  const filters = globalFilters;
  
  const templates = useSelector(state => state.endpoints);
  const template = templates?.find(template => template.cod === chart?.cod);

  const dispatch = useDispatch();

  //   function fetchData(body) {

  //   console.log("fetch")

  //       fetch(template.config.url, {
  //         method: 'POST',
  //         heders: {
  //           'Accept': 'application/json',
  //           'Content-type':'application/json'
  //         },
  //         body: body
  //       }).then(response => {
  //         return response.json();
  //         }).then(data => {
  //           // if (Object.keys(filters).length == 0) {
  //           //   if (template.data === undefined) {
  //           //     dispatch(saveData(template, data));
  //           //   }else{
  //           //     if (chart?.data !== undefined) {
  //                 // dispatch(saveChartData(page.id, chart.id, data))
  //           //     }
  //           //   }
  //           // }else{
  //           //     if ('filtri diversi') {
  //           //         dispatch(saveChartData(page.id, chart.id, data))
  //           //     }
  //           // }

  //             dispatch(saveData(template, data));
  //         }).catch(error => {
  //           // console.log("Error: " + error);
  //         }) 
  // }


    // useEffect(() => {

              // const body = new FormData();
                  
              // const keys = Object.keys(filters)

              // keys?.map((key) => {
              //   const array_values = filters[key];

              //   addFilter(body, key, array_values.join("&"))

              // });

    //   fetchData();

  // }, [])

  const url = 'https://api.sonardigital.it/format/format.php?api=' + template?.endpoint;

  function fetchData(body) {

    fetch(url, {
      method: 'POST',
      heders: {
      'Accept': 'application/json',
      'Content-type':'application/json'
      },
      body: body
      }).then(response => {
        return response.json();
      }).then(data => {

        if (Object.keys(filters).length == 0) {
          if (template.data === undefined) {

            dispatch(saveData(template, data));
          }else{
            if (chart?.data !== undefined) {

                dispatch(saveChartData(page.page_id, chart.id, data))
            }
            // console.log("3");
            dispatch(saveChartData(page.page_id, chart.id, data))

          }

        }else{
            if ('filtri diversi') {
                dispatch(saveChartData(page.page_id, chart.id, data))
            }

        }
      }).catch(error => {
      // console.log("Error: " + error);
      }) 

  }
  let ref = useRef(0);
  
  useEffect(() => {

    const body = new FormData();

    body.append('token', localStorage.getItem('token'));
    
    // let filter = '';

    // if (Object.keys(globalFilters).length > 0) {
    //   filter = globalFilters;
    // }else{
    //   filter = filters;
    // }
    
    const keys = Object.keys(filters)

    keys?.map((key) => {
      const array_values = filters[key];

      body.append(key, array_values.join("&"));

    });

    if (Object.keys(chart_filters).length == 0) {
      fetchData(body);
    }

}, [chart.cod, filters])


  useEffect(() => {

    const body = new FormData();

    body.append('token', localStorage.getItem('token'));
    
    // let filter = '';

    // if (Object.keys(globalFilters).length > 0) {
    //   filter = globalFilters;
    // }else{
    //   filter = filters;
    // }
    
    const keys = Object.keys(chart_filters)

    keys?.map((key) => {
      const array_values = chart_filters[key];

      body.append(key, array_values.join("&"));

    });

    if (Object.keys(chart_filters).length > 0) {
      fetchData(body);
      ref.current = 0;
    }else{
      if (ref.current < 1) {
        fetchData(body);
        ref.current = 1;
      }
    }


}, [chart_filters])


  }