export const select = (question_id, option_id, value, id, table) => {

    return {
        type: 'select',
        question_id: question_id,
        option_id: option_id,
        value: value,
        id: id,
        table: table

    }
}

export const saveSurvey = (data, user) => {

    return {
        type: 'saveSurvey',
        data: data,
        user: user,
    }
}

export const deleteQuestion = (cod) => {

    return {
        type: 'deleteQuestion',
        cod: cod
    }
}

export const addQuestion = (question) => {

    return {
        type: 'addQuestion',
        question: question
    }
}


export const updateValue = (question, value, field) => {

    return {
        type: 'updateValue',
        question: question,
        value: value,
        field: field,
        question_id: question.id
    }
}


export const addOptions = (id) => {

    return {
        type: 'addOptions',
        question_id: id
    }
}


export const createSection = () => {

    return {
        type: 'createSection'
    }
}

export const deleteOption = (question_id, option_id) => {

    return {
        type: 'deleteOption',
        question_id,
        option_id
    }
}


export const updateOption = (question_id, option_id, value) => {

    return {
        type: 'updateOption',
        question_id: question_id,
        option_id: option_id,
        value
    }
}

