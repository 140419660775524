export const nodes = (node) => {

    return {
        type: 'Nodes',
        node: node
    }
}

export const addFilter = (filter) => {

    return {
        type: 'Filter',
        filter: filter
    }
}

export const addCalendar = (state) => {
    return {
        type: 'Calendar',
        state: state
    }
}

export const addValue = (parameter, value) => {
    return {
        type: 'addValue',
        parameter: parameter,
        value: value
    }
}

export const pageFilter = (column, value) => {

    return {
        type: 'pageFilter',
        column: column,
        value: value
    }
}

export const sliderFilter = (column, value) => {

    return {
        type: 'sliderFilter',
        column: column,
        value: value
    }
}

export const toggleSidebar = (column, value) => {
    
    return {
        type: 'Sidebar'
    }
}

export const saveData = (template, data) => {
    
    return {
        type: 'saveData',
        template: template,
        data: data
    }
}

export const sidebarStatus = () => {
    
    return {
        type: 'sidebarStatus'
    }
}

export const saveFilters = (page, chart, column, value, template) => {
    
    return {
        type: 'saveFilters',
        page: page,
        chart_id: chart,
        column: column,
        value: value,
        template: template
    }
}

export const saveRangeFilters = (page, chart, column, value, template) => {
    
    return {
        type: 'saveRangeFilters',
        page: page,
        chart_id: chart,
        column: column,
        value: value,
        template: template
    }
}

export const deleteGroup = (column) => {

    return {
        type: 'deleteGroup',
        column: column
    }
}

export const toggleColumn = (column) => {

    return {
        type: 'toggleColumn',
        column: column
    }
}

export const sort = (obj) => {

    return {
        type: 'sort',
        obj: obj
    }
}

export const search = (value) => {

    return {
        type: 'search',
        value: value
    }
}


export const showNotification = (message) => {

    return {
        type: 'showNotification',
        message: message
    }
}

export const saveEndpoints = (data) => {

    return {
        type: 'saveEndpoints',
        data: data
    }
}

export const databaseSidebar = () => {

    return {
        type: 'databaseSidebar'
    }
}


export const databaseData = (data) => {

    return {
        type: 'databaseData',
        data: data
    }
}

export const saveUserSurvey = (data) => {

    return {
        type: 'saveUserSurvey',
        data: data
    }
}

export const addRecord = () => {

    return {
        type: 'addRecord'
    }
}

export const changeFilters = (page, chart_id, filter_type, value) => {

    return {
        type: 'changeFilters',
        page: page,
        chart_id: chart_id,
        filter_type: filter_type,
        value: value

    }
}
