import { useEffect } from "react";
import { uniqueId } from "../config/functions/uniqueid";

const charts = ['bar2d', 'column2d', 'pie2d','doughnut2d'];

function updatePages(update) {

    const url = "https://setup.therapyknowledge.com/endpoints/pages/update.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        token: localStorage.getItem('token'),
        page_id: update.page_id,
        column: update.column,
        value: update.value
    })
    }).then(response => {
      return response.json();
      }).catch(error => {
        console.log("Error: " + error);
      })

}

function createPages(create) {

    const url = "https://setup.therapyknowledge.com/endpoints/pages/create.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        token: localStorage.getItem('token'),
        unique_key: 'psoriasi',
        page_id: create.page_id,
        title: create.title
    })
    }).then(response => {
      return response.json();
      }).catch(error => {
        console.log("Error: " + error);
      })

}

function updateChart(update) {

    console.log(update);

    const url = "https://setup.therapyknowledge.com/endpoints/charts/update.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        column: update.column,
        value: update.value,
        id: update.chart_id
    })
    }).then(response => {
      return response.json();
      }).catch(error => {
        console.log("Error: " + error);
      })


}

function createChart(create) {

    const url = "https://setup.therapyknowledge.com/endpoints/charts/create.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        page_id: create.page,
        chart_id: create.id,
        token: localStorage.getItem('token')
    })
    }).then(response => {
      return response.json();
      }).catch(error => {
        console.log("Error: " + error);
      })


}

function deleteChart(item) {

    const url = "https://setup.therapyknowledge.com/endpoints/charts/delete.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        page: item.page,
        id: item.id
    })
    }).then(response => {
      return response.json();
      }).then(data => {
      }).catch(error => {
        console.log("Error: " + error);
      })


}

const filter = {
    values: {
        //min: '-Infinity',
        min: '-Infinity',
        max: 'Infinity'
    },
    labels: []
}
const divider = 20; 

const dashboard = '/dashboard/';
const initialState = [
    {
    id: 'home',
    link: '/',
    group: 'home',
    title: 'Home',
    charts: []},
    {
    id: '01',
    link: dashboard,
    group: 'AA',
    title: 'Sample',
    charts: [
        {id: 'sfdh', title: '', endpoint: 'AAI', limit: '', divider: divider, type: charts[1], size: 6, filters: filter, page: 1, data: {}},
        {id: 'sdg3r', title: '', endpoint: 'AAI', limit: '', divider: divider, type: charts[2], size: 4, filters: filter, page: 1, data: {}},
 
     ]},
    {
    id: '02',
    link: dashboard,
    group: 'AAA',
    title: 'Physician\'s profile',
    charts: [
        {id: 'A', title: '', endpoint: 'AAA', limit: '', divider: divider, type: 'column2d', size: 6, filters: filter, page: 1, data: {}},
        {id: 'B', title: '', endpoint: 'AAB', limit: '', divider: divider, type: charts[3], size: 4, filters: filter, page: 1, data: {}},
        {id: 'C', title: '', endpoint: 'AAR', limit: '', divider: divider, type: charts[1], size: 4, filters: filter, page: 1, data: {}},
        {id: 'D', title: '', endpoint: 'AAC', limit: '', divider: divider, type: charts[0], size: 6, filters: filter, page: 1, data: {}},
        {id: 'E', title: '', endpoint: 'AAE', limit: '', divider: divider, type: charts[1], size: 6, filters: filter, page: 1, data: {}},
        {id: 'F', title: '', endpoint: 'AAD', limit: '', divider: divider, type: charts[0], size: 4, filters: filter, page: 1, data: {}}
 
     ]},
    {
    id: '02A',
    link: dashboard,
    group: 'AAA',
    title: 'Patients type',
    charts: [
        {id: 'sfdh', title: '', endpoint: 'Empty', limit: '', divider: divider, type: charts[1], size: 5, filters: filter, page: 1, }
 
     ]},
    {
    id: '02B',
    link: dashboard,
    group: 'AAA',
    title: 'Cytometry testing',
    charts: [
        {id: 'sdggfawrd', title: '', endpoint: 'Empty', limit: '', divider: divider, type: charts[1], size: 5, filters: filter, page: 1}
     ]},
    {
    id: '02C',
    link: dashboard,
    group: 'AAA',
    title: 'Knowledge of products',
    charts: [
        {id: 'hr6tuad', title: '', endpoint: 'Empty', limit: '', divider: divider, type: charts[1], size: 5, filters: filter, page: 1}
     ]},
    {
    id: '03',
    link: dashboard,
    title: 'Patients',
    group: 'AAE',
    charts: [
        {id: 'agfi4ebcu', title: 'Età', endpoint: 'AAT', limit: '', divider: divider, type: charts[0], size: 6, filters: filter, page: 1},
        {id: 'sdgsdg', title: '', endpoint: 'AAL', limit: '', divider: divider, type: charts[3], size: 4, filters: filter, page: 1},
        {id: 'AAAF', title: '', endpoint: 'AAM', limit: '', divider: divider, type: charts[3], size: 4, filters: filter, page: 1},
        {id: 'sdgsd', title: '', endpoint: 'AAN', limit: '', divider: divider, type: charts[0], size: 6, filters: filter, page: 1},
        {id: 'shay', title: '', endpoint: 'AAO', limit: '', divider: divider, type: charts[3], size: 5, filters: filter, page: 1},

     ]},
    {
    id: '04',
    link: dashboard,
    title: 'Current clinical status',
    group: 'AAB',
    charts: [
        {id: 'AAAF', title: '', endpoint: 'AAH', limit: '', divider: divider, type: charts[1], size: 6, filters: filter, page: 1},
        {id: 'sdgsdg', title: '', endpoint: 'AAP', limit: '', divider: divider, type: charts[1], size: 4, filters: filter, page: 1}
     ]},
    {
    id: '04B',
    link: dashboard,
    title: 'Clinical condition',
    group: 'AAB',
    charts: [
        {id: 'AAAF', title: 'Symptoms/associated manifestations', endpoint: 'Empty', limit: '', divider: divider, type: charts[2], size: 5, filters: filter, page: 1}
     ]}
    ,{
    id: '04D',
    link: dashboard,
    title: 'Next visit',
    group: 'AAB',
    charts: [
        {id: 'asfa', title: 'When is the patient\'s next appointment?', endpoint: 'Empty', limit: '', divider: divider, type: charts[2], size: 5, filters: filter, page: 1}
     ]}
    ,{
    id: '05',
    link: dashboard,
    title: 'Current therapy',
    group: 'AAC',
    charts: [
        {id: 'AAAF', title: '', endpoint: 'AAB', limit: '', divider: divider, type: charts[1], size: 5, filters: filter, page: 1},
        {id: 'sdgsdg', title: '', endpoint: 'AAE', limit: 100, divider: divider, type: charts[1], size: 5, filters: filter, page: 1}
     ]}
    ,{
    id: '05B',
    link: dashboard,
    title: 'Share',
    group: 'AAC',
    charts: [
        {id: 'sdgsdg', title: '', endpoint: 'ABA', limit: '', divider: divider, type: charts[1], size: 4, filters: filter, page: 1},
        {id: 'AAAF', title: '', endpoint: 'AAV', limit: '', divider: divider, type: charts[0], size: 6, filters: filter, page: 1},
        {id: 'sdgsdg', title: '', endpoint: 'AAZ', limit: '', divider: divider, type: charts[0], size: 10, filters: filter, page: 1}
     ]}
    ,{
    id: '05D',
    link: dashboard,
    title: 'Regimens',
    group: 'AAC',
    charts: [
        {id: 'sdgsdg', title: '', endpoint: 'ABB', limit: '', divider: divider, type: charts[0], size: 10, filters: filter, page: 1}
     ]}
    ,{
    id: '18',
    link: dashboard,
    title: 'Previous therapy',
    group: 'AAD',
    charts: [
        {id: 'AAAF', title: '', endpoint: 'AAB', limit: '', divider: divider, type: charts[1], size: 5, filters: filter, page: 1},
        {id: 'sdgsdg', title: '', endpoint: 'AAD', limit: '', divider: divider, type: charts[1], size: 5, filters: filter, page: 1}
    ]}
    ,{
    id: '18A',
    link: dashboard,
    title: 'Switch',
    group: 'AAD',
    charts: [
        {id: 'AAAF', title: '', endpoint: 'AAU', limit: '', divider: divider,  type: charts[1], size: 10, filters: filter, page: 1},
        {id: 'Afsdf', title: '', endpoint: 'ABC', limit: '', divider: divider, type: charts[1], size: 10, filters: filter, page: 1}
    ]}
    ];

const Pages = (state = [], action) => {

    const page = state.find(page => page.page_id === action.page);
    const chart = page?.charts?.find(chart => chart.id === action.chart_id);
    const newId = uniqueId();

    const newChart = {
        id:  newId,
        title: 'Configura il tuo grafico',
        chart_id: newId,
        endpoint: 'Empty',
        limit: '',
        divider: 20,
        type: charts[0],
        size: 6,
        page: 1
    }  
                
    const pageCode = uniqueId();

    const newPage = {
        id:  pageCode,
        page_id: pageCode,
        charts: [],
        title: 'New dashboard',
        link: '',
        group: action.group,
        charts: [
            newChart
        ]
        
    } 
    
    switch(action.type) {
        case 'fetchPages':

        state = action.data

        return state = [...state];

        case 'fetchCharts':

        if (page !== undefined) {
            page.charts = action.data;
        }

        return state = [...state];


        case 'changeEndpoint':

            updateChart({
            column: 'cod',
            value: action.endpoint,
            id: chart?.id,
            chart_id: chart.chart_id
            });

            chart.cod = action.endpoint;
            chart.data = undefined;
            return state = [...state];

        case 'changeType':
        
            updateChart({
            column: 'type',
            value: action.chart_type,
            id: chart.id,
            chart_id: chart.chart_id
            });

            chart.type = action.chart_type;
            return state = [...state];

        case 'deleteChartFilters':

            chart.filters = {};
            return state = [...state];

        case 'saveChartData':
            chart.data = action.data;
            return state = [...state];

        case 'addChart':   
            
            createChart({
                page: page.page_id,
                id: newChart.id
            })

            console.log(page);
            page.charts = [...page.charts, newChart]

            return state = [...state];

        case 'reSize':

            updateChart({
            column: 'size',
            value: action.size,
            id: chart.id,
            chart_id: chart.chart_id
            });

            chart.size = action.size
            return state = [...state];

        case 'remove':
                
            deleteChart({
                page: page.page_id,
                id: chart.id
            });

            page.charts = page.charts.filter(chart => chart.id !== action.chart_id)
            return state = [...state];

        case 'addPages':       
            
            createPages(newPage);
            createChart({
                page: newPage.page_id,
                id: newPage.id
            })
            return state = [...state, newPage];

        case 'changePageTitle':       
            
            page.title = action.value;
            
            updatePages({
                column: 'title',
                value: action.value,
                page_id: page.page_id
            })

            return state = [...state];

        case 'saveFilters':


        if (action.page === undefined && action.chart_id === undefined) {
            return state;
        }

            chart?.filters === undefined && (chart.filters = {})

                function setFilters(obj, action) {


                    if (obj[action.column] !== undefined) {
                
                        if (!obj[action.column].includes(action.value)) {
                            obj[action.column].push(action.value);
                        }else{
                
                            if (obj[action.column].length > 1) {
                                obj[action.column] = obj[action.column].filter(value => value !== action.value);
                            }else{
                                delete obj[action.column]
                            }
                        }
                        
                    }else{
                        obj[action.column] = [action.value]
                    }

                    if (Object.keys(obj).length > 0) {
                        return obj;
                    }else{
                        return undefined;
                    }
                }

                chart.filters = setFilters(chart.filters, action);

                chart.filters = {...chart.filters};
    
                return state = [...state];

        case 'saveRangeFilters':

                chart.filters = chart.filters === undefined ? {} : chart.filters;

            if (!chart.filters[action.column]?.includes(action.value)) {
                chart.filters[action.column] = [];
                chart.filters[action.column].push(action.value);
            }else{
                chart.filters[action.column] = [];
            }

                console.log(chart);

                return state = [...state];

        case 'changeTitle':            
        
                updateChart({
                    column: 'title',
                    value: action.text,
                    id: chart.id,
                    chart_id: chart.chart_id
                    });

            page.title = action.text
            return state = [...state]

        case 'changeFilters':
            
            chart.filters.values[action.filter_type] = action.value;
            return state = [...state];

        case 'changeDivider':  

            updateChart({
            column: 'divider',
            value: action.value,
            id: chart.id,
            chart_id: chart.chart_id
            });

            chart.divider = action.value;
            return state = [...state];

        case 'changeLimit':

            updateChart({
            column: 'chart_limit',
            value: action.value,
            id: chart.id,
            chart_id: chart.chart_id
            });
            
            chart.limit = action.value;
            return state = [...state];

        case 'changePage':

            chart.page = action?.value;
            return state = [...state];

        case 'chartTitle':

            updateChart({
                column: 'title',
                value: action.value,
                id: chart.id,
                chart_id: chart.chart_id
                });

            chart.title = action.value;
            return state = [...state];

        case 'drag':
                    
            const dragged_chart_index = page.charts.indexOf(action.chart);
            const target_chart_index = page.charts.indexOf(action.target);

            page.charts.splice(target_chart_index, 1, action.chart);
            page.charts.splice(dragged_chart_index, 1, action.target);

            return state = [...state];

        default:
            return state
    }
}

export default Pages;