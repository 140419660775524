import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export function usePage() {

    const params = useParams();
    const page = useSelector(state => state.pages).find(page => page.page_id === params.id);

    return page;

}
