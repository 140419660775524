import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { select } from "../../actions/survey";
import { showNotification } from "../../../../actions";
import tick_icon from '../../../../media/icons/tick.png';

export default function Text(props) {

    const dispatch = useDispatch();
    const params = useParams();

    const table = props.table;

    const question = props.question;
    const option = props.option;

    const min = option.min;
    const max = option.max;

    const user = props.user;

    const [value, setValue] = useState(question.value);
    const [error, setError] = useState();

    const active = value !== '' ? 'active' : '';
    let timeout = null;

    const ref = useRef()

    function inputValue(e) {

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            if (e.target.value.length > 0 && e.target.value.length < 1000) {
                setValue(e.target.value);
                setError('')
                dispatch(select(question.id, option.cod, e.target.value,  user.id, table));
            }else{
                setValue('');
                setError(e.target.value);
                ref.current.value=""
                dispatch(select(question.id, option.cod, '',  user.id, table));
            }
            dispatch(showNotification())
        }, 800);

        
    }

    function ErrorMessage(props) {

        const error = props.error;
        let message = 'Write anything';


        return(
            message.length == 0 &&
            <div className="flex light" style={{width: '100%', paddingTop: '5px'}}>
                <h6>{message}</h6>
            </div>
        )
    }

    return(
        <>
            <div className={"flex y-a question-number " + active}  style={{boxSizing: 'boder-radius',  paddingRight: '15px', minHeight: '45px', width: 'auto', borderRadius: '5px', gap: '15px', paddingLeft: '15px'}}>
                <div>
                    <div  className="flex x-a y-a" style={{width: '30px', height: '30px', backgroundColor: 'var(--color5)', borderRadius: '100%'}}>
                        <img className="tick_icon" src={tick_icon} style={{width: '70%', filter: 'invert(1)'}}></img>
                    </div>
                </div>
                <div style={{width: '100%'}}>
                    <input ref={ref} defaultValue={question.value} placeholder={option.title} className="bold" onChange={(e) => inputValue(e)}></input>
                </div>
            </div>
            <ErrorMessage 
            error={error}/>
        </>
    )
}