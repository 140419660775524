import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

import { useSelector } from "react-redux";

import { useFetch } from "../../config/charts/usefetch.js";
import { pageDefine, pagination } from "../../config/charts/pagination.js";
import { Toolbar } from "./toolbar.js";

import empty_chart_icon from '../../media/icons/charts.svg';

import { t } from '../../config/functions/translate.js';

ReactFusioncharts.fcRoot(FusionCharts, FusionTheme);

charts(FusionCharts);

function Title(props) {

  const title = props.info.title;
  const description = props.info.description;

  return(
    <div className="flex-column x-a y-a" style={{gap: '2px', paddingTop: '15px'}}>
        <h4 className="chart-text bold">{t(title)}</h4>
    </div>
  )
}

function Cover() {
  return(
      <div className="flex x-a y-a" style={{width: '100%', height: '100%'}}>
        <div className="layer" style={{position: 'absolute', backgroundColor: 'var(--layer)', width: '100%', height: '100%', zIndex: '35'}}></div>
        <img src={empty_chart_icon} style={{width: '270px'}}></img>
      </div>
  )
}

export default function Chart(props) {
  const chart = props.chart; 

  const templates = useSelector(state => state.endpoints);
  const template = templates?.find(template => template.cod === chart?.cod);

  const page = props.page;

  const data = ((chart?.data === undefined) ? template?.data : chart?.data);

  useFetch(chart, page);

  const pages = pageDefine(data?.data?.length, chart?.divider, (chart?.page !== 'undefined' ? chart.page : 1));

  const graph = pagination(data?.data, pages);

  const span = 'span ' + chart.size;

  const info = {
    title: (chart?.title !== '' && chart?.title !== undefined) ? chart?.title : template?.title,
    description: (chart?.description !== '' && chart?.description !== undefined) ? chart?.description : template?.description
  }

  const total = (data?.total !== undefined && data?.total !== null) ? ('Base ' + (data?.total || '')) : ''

  const colors = (chart.type === 'pie2d' || chart.type === 'doughnut2d') ? 'DAB44D,  484D62, 838D9C, 5E64A1, 838D9C, 7EB1AB' : '5594B6';

  const values = (chart.type === 'column2d' || chart.type === 'bar2d') ? 1 : 0;
  
  const labels = (chart.type === 'column2d' || chart.type === 'bar2d') ? 1 : 0;

  const dataSource = {
    chart: {
      animation: 0,
      animationDuration: 0.5,
      caption: '',
      subcaption: '',
      xaxisname: '',
      yAxisMaxValue: (chart.limit !== '' ? chart.limit : ''),
      yaxisname: '',
      palettecolors: colors,
      numbersuffix: template?.numbersuffix,
      showvalues: values,
      labelFontColor: '00000',
      enableSmartLabels: 0,
      showLabels: labels,
      showLegend: 1,
      decimals: 1,
      labelDisplay: 'AUTO',
      creditLabel: false,
      numVisibleLabels: 12,
      exportEnabled: true,
      theme: "fusion",
    },
    data: graph
  };

  const value = graph !== undefined ? graph[0]?.value : '';

  const text = !isNaN(value) ? parseInt(value).toFixed(1) : value

    return (
      <>
      
        <div className="chart flex-column border-box "
        draggable={true} 
        onDragStart={(e) => {props.drag(chart);}}
        onDragOver={(e) => {e.preventDefault(); props.target(chart);}} 
        onDragEnd={(e) => props.drop()} 
        style={{position: 'relative', width: '100%', gridColumn: span, padding: '15px', paddingTop: '10px',  paddingBottom: '10px', border: ((props.draggedChart !== chart && props.targetChart !== chart) ? '1px solid var(--border--color)' : '1px solid var(--color7)'), backgroundColor: 'white', borderRadius: 'var(--widget--radius)', overflow: 'hidden'}}>
        
        <div className="flex-column x-a" style={{width: '100%', minHeight: '55px', marginBottom: '3px', gap: '1px'}}>
       
        {
          (props.targetChart === chart && props.draggedChart !== chart) &&
          <div style={{position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', backgroundColor: 'var(--color7)',  zIndex: '80'}}></div>
          
        }
          <Title 
          info={info}/> 


          </div>
            
          <h6 className="chart-text light" style={{lineHeight: '21px', textAlign: 'center'}}>{info.description}</h6>

          <div className="flex-column x-a" style={{position: 'relative', width: '100%', minHeight: '125px', overflow: 'hidden', borderRadius: '10px', gap: '15px'}}>

            {
              (template?.data === undefined) ? 

              <Cover />

              :
              <>
                <div className="flex x-a y-a" style={{width: '100%', height: '100%'}}>
                  <div className="flex-column x-a y-a" style={{width: '50%', height: '100%', gap: '3.5px'}}>
                    {/* <h4 className="bold light">{data?.total !== undefined && 'Base'}</h4> */}
                    {/* <h3 className="bold light">{data?.total}</h3> */}
                    <h4 className="bold light">{template?.xaxisname}</h4>
                  </div>
                  <div style={{width: '1px', height: '60%', backgroundColor: 'var(--border--color)'}}></div>
                  <div className="flex-column x-a y-a" style={{width: '50%', height: '100%', gap: '3.5px'}}>
                    <h3 className="bold">{text}{template?.numbersuffix}</h3>
                  </div>
                </div>
              </>
              
            }           
            
          </div>
          {/* {
            <Toolbar 
            tools_visibility='none'
            page={page}
            chart={chart}
            template={template}
            pages={pages}
            size={chart.size}/>

          } */}


        </div>

      </>
    );
}