export function pageDefine(list, divider, page) {

    divider = divider === '' ? '20' : divider

    return {
        page: page, 
        list: (list !== undefined ? list : 1), 
        divider: divider,
        pages: Math.ceil((list || 1)/divider)
    };
}

export function pagination(data, pageDefine) {
    const pageRangeA = ((pageDefine.page - 1) * pageDefine.divider)
    const pageRangeB = (pageDefine.page * pageDefine.divider)
    if (data !== undefined) {
        data = data?.filter((item, index) => index >= pageRangeA && index < pageRangeB)
    }

    return data;
}