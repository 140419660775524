import { useEffect } from "react";
import { Route, Routes} from "react-router-dom";
import './App.css';
import './classes.css';
import './desktop.css';

import Home from "./pages/home";
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Map from './pages/map';

import Doctors from './pages/edge-doctors/doctors';
import Hospitals from './pages/edge-doctors/hospitals';
import Studies from './pages/edge-doctors/studies';
import Patients from './pages/edge-doctors/patients';

import Physician from './pages/physician';

import { fetchPages } from "./actions/pages";
import { useDispatch } from "react-redux";
import Survey from "./modules/survey/survey";
import FusionCharts from "fusioncharts";
import { saveEndpoints } from "./actions";
import Favourite from "./pages/favourite";

const auth = localStorage.getItem('token');
localStorage.setItem('language', 'it');

function App() {
  
  FusionCharts.options.license({key: 'LpB5xgkB2F3D2B16A11C8B4F4A3A3A1A1A1hD-17sF6B1dbhvcC6C5znD-8I3A1A7A1C7A6E6E1D4D4E1B9B6E2B2E2nqsG1C3C8cE6D4C4vulC8D5B5jnB-8E3E1G2B-21fbC2A3E2D3I2H3A4D6D1D3D1emoA32B2a1D8akzH4B3B2kzA5MC1E3vpaC5B7B2D5B4E4H4B2B2B8A5A4B3E6y==',   creditLabel: false, });

  const dispatch = useDispatch();

    useEffect(() => {
      
       // const body = new FormData();
       // keys?.map((key) => {
       //   const array_values = filters[key]
       //   addFilter(body, key, array_values.join("&"))
       // });
   
       const url = 'https://setup.therapyknowledge.com/endpoints/pages/read.php';
   
       fetch(url, {
         method: 'POST',
         heders: {
           'Accept': 'application/json',
           'Content-type':'application/json'
         },
         body: JSON.stringify({
          "token": localStorage.getItem('token')
         })
       }).then(response => {
         return response.json();
         }).then(data => {
          dispatch(fetchPages(data.data))
         }).catch(error => {
           console.log("Error: " + error);
         })
   
       }, [])

      useEffect(() => {
    
      // const body = new FormData();
      // keys?.map((key) => {
      //   const array_values = filters[key]
      //   addFilter(body, key, array_values.join("&"))
      // });
  
      // const url = 'https://api.sonardigital.it/format/format.php?api=endpoints';
      const url = 'https://setup.therapyknowledge.com/endpoints/endpoints/read.php';
  
      fetch(url).then(response => {
        return response.json();
        }).then(data => {
          dispatch(saveEndpoints(data.data))
        }).catch(error => {
          console.log("Error: " + error);
        })
  
      }, [])

  return (
    <Routes>
        {
          (auth) ?
          <>
          <Route path="/" element={<><Home /></>}/>
          <Route path="/home" element={<><Home /></>}/>
          <Route path="/dashboard/:id" element={<><Dashboard /></>}/>
          <Route path="/my-dashboard/:id" element={<><Favourite /></>}/>
          <Route path="/survey" element={<><Survey /></>}/>
          <Route path="/database" element={<><Doctors /></>}/>
          <Route path="/map" element={<><Map /></>}/>
          <Route path="/*" element={<><Home /></>}/>


          //* admin
          <Route path="/doctors" element={<><Doctors /></>}/>
          <Route path="/hospitals" element={<><Hospitals /></>}/>
          <Route path="/studies" element={<><Studies /></>}/>

          <Route path="/physician" element={<><Physician /></>}/>
          <Route path="/patients" element={<><Patients /></>}/>
          <Route path="/survey/:survey" element={<><Survey /></>}/>
          <Route path="/survey/:survey/:id" element={<><Survey /></>}/>
          </>

          : 

          <Route path="/*" element={<><Login /></>}/>

        }
        
        {
          (!auth) &&
          <Route path="/login" element={<><Login /></>}/>
        }
        

       
    </Routes>
  )

}

export default App;
