import { useDispatch, useSelector } from "react-redux";

import { sort } from "../../../actions";
import direction_icon from '../../../media/icons/arrow.svg';
import { Link } from "react-router-dom";

import { style } from '../../../components/table/style/style.css';

import { pagination, pageDefine } from "../../../config/charts/pagination";

import { addRecord, changeTablePage, options, toggleModule, toggleSection } from "../actions/table";

import { group } from "../../../config/functions/group";

import arrow_icon from '../../../media/icons/arrow.svg';
import { sortBy } from "../functions/sortby";

import { t } from "../functions/translate";
import { useRef } from "react";
import Details from "./details";

// passare l'array di oggetti
// dividere in sezioni
//

function Header(props) {
    const index = props.number;

    const row = props.row;

    const keys = Object.keys(row);
    
    const field = props.field;
    const order = props.order;

    const width = props.width;

    const dispatch = useDispatch();

    const state = useSelector(state => state.database);

    function Direction(props) {

        const title = props.title;

        if (state.sorting.field === title && title !== '') {

            switch(state.sorting.direction) {
                case '':
                    return(
                        <img src={direction_icon} style={{width: '12px', transform: 'rotate(180deg)'}}></img>
                    )
                case 'DESC':
                    return(
                        <img src={direction_icon} style={{width: '12px', transform: 'rotate(360deg)'}}></img>
                    )
                default:
                    return state
            }

        }
        
    }

    function Main(props) {
        
        const title = props.title !== '0000-00-00' ? props.title : '';

        const width = props.width;

        return(
            <div className="flex y-a user-select" style={{width: '100%', height: '100%', position: 'sticky', top: '0', left: '0', right: '0', zIndex: '250'}} onClick={() => dispatch(sort(title))}>
                <div className="cell cell-main flex y-a" style={{boxSizing: 'border-box', minWidth: width, height: '100%', gap: '10px', backgroundColor: 'var(--body--color)'}}>
                    <h6 className="bold">{t(title)}</h6>
                    <Direction
                    title={title}/>
                </div>
            </div>
        )
    }

    function Cell(props) {
        
        const title = props.title !== '0000-00-00' ? props.title : '';

        const Classes = title === '' ? ' x-a' : '';

        const width = props.width;

        return(
            <div className="flex y-a user-select" style={{width: 'auto', height: '100%'}} onClick={() => dispatch(sort(title))}>
                <div className={"cell flex y-a" + Classes} style={{boxSizing: 'border-box', minWidth: width, height: '100%', backgroundColor: 'var(--body--color)', gap: '10px'}}>
                    <h6 className="bold"  spellCheck="false" style={{color: 'black'}}>{t(title)}</h6>
                    <Direction
                    title={title}/>
                </div>
            </div>
        )
    }
    
    const main = props.main;
   
    return(
            <div className="flex y-a row" style={{display: 'inline-flex', flexWrap: 'none', width: 'auto', backgroundColor: 'var(--body--color)', zIndex: '350'}}>
                
                {/* <Cell
                title={''}
                width='55px'/> */}

                {
                    keys?.map((key, index) => {
                        if (main === row[key]){
                            return(
                                <Main
                                key={index}
                                width={width}
                                title={row[key]}/>
                            )
                        }else{
                            return(
                                <Cell 
                                key={index}
                                width={width}
                                title={row[key]}/>
                            )
                        }

                        
                    })
                }
                
            </div>
    )
}

function Row(props) {

    const index = props.number;

    const row = props.row;

    const main = props.main;

    const columns = props.columns;

    const keys = Object.keys(row);

    const width = props.width;

    function Cell(props) {

        const database = useSelector(state => state.database);

        const dispatch = useDispatch();       
        
        const ref = useRef();
         
        const title = props.title !== '0000-00-00' ? props.title : '';
    
        const width = props.width;

        const column = props.column;
    
        const cell_main = props.column === props.main && ' cell-main';
        const main = props.column === props.main && ' main';

        function inputValue(e) {
            const list = [...new Set(database?.data?.map(row => row[column]).flat().filter(item => item !== ''))];
            // console.log(e.target.getBoundingClientRect().top);
            // dispatch(options(list, row, column, e.target.getBoundingClientRect().top, e.target.getBoundingClientRect().left))
        }
    
        return(
            <div className={"flex y-a user-select" + main} style={{width: 'auto', height: '100%'}}>
                <div className={"cell flex y-a " + cell_main} style={{boxSizing: 'border-box', minWidth: width, height: '100%'}}>
                    {/* <h6 className="bold">{title}</h6> */}
                    <input ref={ref} className="bold" defaultValue={title} spellCheck="false" onClick={(e) => e.currentTarget.select()} style={{border: 'none', background: 'none', paddingTop: '8px', fontSize: '15px'}} onInput={(e) => inputValue(e)}></input>
                    {
                        cell_main != false &&
                        <div className="flex x-a y-a details_btn pointer" style={{width: '80px', height: '75%', position: 'absolute', right: '10px', gap: '5px'}} onClick={() => dispatch(toggleModule(row))}>
                            <h6 style={{fontSize: '13.5px'}}>{t('Dettagli')}</h6>
                            <img src={arrow_icon} style={{width: '13px',  transform: 'rotate(-90deg)'}}></img>
                        </div>
                    }
                </div>
            </div>
        )
    }

    return(
        // <Link to={"/survey/" + row['pid']}>
        <div className="flex y-a row" style={{display: 'inline-flex', flexWrap: 'none', minWidth: '100%', width: 'auto'}}>

            {/* <Cell
            title={index}
            width='55px'/> */}

            {
                columns?.map((key, index) => {
                    // if (main === key){
                    //     return(
                    //         <Main
                    //         key={index}
                    //         width={width}
                    //         title={row[key]}/>
                    //     )
                    // }else{
                        return(
                            <Cell 
                            width={width}
                            key={index}
                            title={row[key]}
                            column={key}
                            main={main}
                            row={row}/>
                        )
                    // }

                    
                })
            }
            
        </div>
        // </Link>
    )
}

function Section(props) {

    const index = props.number;

    const row = props.row;

    const main = props.main;

    const columns = props.columns;
    
    const state = useSelector(state => state.database);

    const keys = Object.keys(row);

    const dispatch = useDispatch();

    const width = props.width;

    function Cell(props) {
    
        const title = props.title !== '0000-00-00' ? props.title : '';
    
        const width = props.width;
    
        const cell_main = props.column === props.main && ' cell-main';
        const main = props.column === props.main && ' main';
    
        return(
            <div className={"flex row-section user-select"} style={{width: 'auto', height: '100%', position: 'sticky', top: '0', left: '0', zIndex: '250', border: 'none'}}>
                <div className={"cell flex " + cell_main} style={{boxSizing: 'border-box', minWidth: width, height: '100%', border: 'none'}}>
                    <div className="flex y-a" style={{gap: '8px', paddingTop: '10px'}}>
                        {
                            title !== undefined &&
                            <>
                                {

                                    state.hidden_sections.includes(title) ?
                                    <img src={arrow_icon} onClick={() => dispatch(toggleSection(title))} style={{width: '14px', transform: 'rotate(270deg)'}}></img>
                                    :
                                    <img src={arrow_icon} onClick={() => dispatch(toggleSection(title))} style={{width: '14px', transform: 'rotate(0deg)'}}></img>
                                }
                            </>
                        }
                        <h4 className="bold" style={{fontSize: '16px'}}>{title}</h4>
                        {/* <input className="bold" defaultValue={title} onClick={(e) => e.currentTarget.select()} style={{border: 'none', background: 'none', paddingTop: '5px'}}></input> */}
                    </div>
                </div>
            </div>
        )
    }

    return(
        // <Link to={"/survey/" + row['pid']}>
        <div className="flex row-section " style={{display: 'inline-flex', flexWrap: 'none', minWidth: '100%', width: 'auto', height: '45px'}}>

            {/* <Cell
            title={index}
            width='55px'/> */}

            {
                columns?.map((key, index) => {
                    // if (main === key){
                    //     return(
                    //         <Main
                    //         key={index}
                    //         width={width}
                    //         title={row[key]}/>
                    //     )
                    // }else{

                        return(
                                <Cell 
                                width={width}
                                key={index}
                                title={row[key]}
                                column={key}
                                main={main}/>
                        )
                    // }

                    
                })
            }
            
        </div>
        // </Link>
    )
}

function NewRow(props) {

    const dispatch = useDispatch();

    const index = props.number;

    const row = props.row;

    const main = props.main;

    const columns = props.columns;

    const keys = Object.keys(row);

    const width = props.width;

    const section = props.section;

    function Cell(props) {
    
        const title = props.title !== '0000-00-00' ? props.title : '';
    
        const width = props.width;
    
        const cell_main = props.column === props.main && ' cell-main';
        const main = props.column === props.main && ' main';
    
        return(
            <div className={"flex y-a row-section user-select"} style={{width: 'auto', height: '100%', position: 'sticky', top: '0', left: '0', zIndex: '250', border: 'none'}} onClick={() => dispatch(addRecord(section))}>
                <div className={"cell flex y-a " + cell_main} style={{boxSizing: 'border-box', minWidth: width, height: '100%', border: 'none'}}>
                    {/* <h6 className="bold">{title}</h6> */}
                    <h5 className="bold" defaultValue={title} onClick={(e) => console.log("here")} style={{border: 'none', background: 'none', fontSize: '16px', color: 'gray', opacity: '1'}}>{t(title)}</h5>
                </div>
            </div>
        )
    }

    return(
        // <Link to={"/survey/" + row['pid']}>
        <div className="flex row-section y-a" style={{display: 'inline-flex', flexWrap: 'none', minWidth: '100%', width: '100vw', height: '35px', marginBottom: '10px'}}>

            {/* <Cell
            title={index}
            width='55px'/> */}

           
                            <Cell 
                            width={width}
                            key={index}
                            title={t('Add a new row')}
                            main={main}/>
                       
        </div>
        // </Link>
    )
}

export default function Table() {

    const state = useSelector(state => state.database);

    const search = useSelector(state => state.search);

    const dispatch = useDispatch();

    const columns = Object?.keys(state?.data[0]).filter(column => state?.columns.includes(column));

    const width = '210px';

    let number = 1;

    let data = state.data.filter(data => (Object.values(data).map(value => {return value?.toString().toLowerCase()})).map(key => {return key?.includes(search?.toLowerCase())}).includes(true));

    const pages = pageDefine(data.length, state?.pagination?.divider, state?.pagination?.page);

    data = pagination(data, pages); 


    const sections = group(data, state?.group);

    // const keys = Object.keys(sections)?.filter(section => state?.hidden_sections.length > 0 ? !state?.hidden_sections.includes(section) : section);
    const keys = Object.keys(sections);

    const numbers = [];

    for (let i = 0; i<pages.pages; i++) {
        numbers.push(i + 1);
    }
    
    return(
        Object.keys(state.data[0]).length > 0 &&
        <>
        <div className="table" style={{dispaly: 'block', width: '100%', height: '100%', overflow: 'scroll'}}>
            
            <div style={{position: 'sticky', top: '0', width: 'auto', zIndex: '350'}}>
                <Header 
                row={columns}
                width={width}
                order={state?.sorting?.field}
                main={state.main}/> 
            </div>

            {
                keys?.map((section, index) => {
                    return(
                        <div key={index} style={{width: '100vw'}}>
                        {
                            section!=='undefined' &&
                            <Section 
                            title={(section !== '' ? section : 'Nessun valore')}
                            key={index}
                            width={width} 
                            columns={columns} 
                            number={number++}
                            main={state.main}
                            row={{[columns[0]]: section}}/>
                        }
                        <div>
                            {
                                sortBy(sections[section], state.sorting)?.map((row, index) => {
                                    
                                    return(
                                        !state?.hidden_sections.includes(section) > 0 &&
                                        <Row
                                        key={index}
                                        width={width} 
                                        columns={columns} 
                                        number={number++}
                                        main={state.main}
                                        row={row}/>
                                    )
                                })
                            }

                            {
                                <NewRow 
                                key={Math.random()}
                                title={(section !== '' ? section : 'Nessun valore')}
                                width={width} 
                                columns={columns} 
                                number={number++}
                                main={state.main}
                                section={section}
                                row={{[columns[0]]: section}}/>
                            }
                            </div>
                        </div>
                    )
                })
                }

                
        </div>

        <Details />
        {/* {
            state.options.length > 0 &&
            // <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh'}}>
                <div className="flex-column" style={{boxSizing: 'border-box', padding: '20px', gap: '5px', position: 'fixed', top: ((state.module.top + 47) + 'px'), left: ((state.module.left - 15) + 'px'), width: '230px', height: '250px', backgroundColor: 'var(--color5)', borderRadius: '10px', zIndex: 500}}>
                    {
                        state.options?.map(option => {
                            return(
                                <h5>{option}</h5>
                            )
                        })
                    }
                </div>
            // </div>
        } */}

        {
            numbers.length > 1 &&
            <div className="flex pointer" style={{gap: '5px'}}>
                {
                    numbers.map((number, index) => {
                        return(
                            <h4 className="" key={index} onClick={() => dispatch(changeTablePage(number))}>{number}</h4>
                        )
                    })
                }
            </div> 
        }
        

        </>
    )
}