import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { showNotification } from "../../../../actions";
import { select } from "../../actions/survey";
import tick_icon from '../../../../media/icons/tick.png';

export default function Date(props) {

    const dispatch = useDispatch();

    const question = props.question;

    let day = '00';
    let month = '00';
    let year= '0000';

    let date = [];

    if (question.value !== '' && question.value !== '0000-00-00') {
        date = question.value.toString().split('-');
        year = date[0].toString();
        month = date[1].toString();
        day = date[2].toString();
    }

    const option = props.option;

    const table = props.table;

    const min = option.min;
    const max = option.max;

    const [value, setValue] = useState('');
    const [error, setError] = useState();

    const params = useParams();

    const user = props.user;

    
    let timeout = null;

    const ref = useRef()

    function inputValue(e, cod) {

        clearTimeout(timeout);
        timeout = setTimeout(function () {
            if (e.target.value >= min && e.target.value <= option.max) {
                
                setValue(e.target.value);
                
                setError('')
                dispatch(select(question.id, option.cod, e.target.value, user.id, table));
            }else{
                setValue('');
                setError(e.target.value);
                
                dispatch(select(question.id, option.cod, e.target.value, user.id, table));
            }
            dispatch(showNotification())

        }, 1200);

        
    }

    function ErrorMessage(props) {

        const error = parseInt(props.error);
        const minValue = props.min;
        const maxValue = props.max;
        let message = '';

        error<minValue && (message='Il valore dev\'essere maggiore di ' + minValue);
        error>maxValue && (message='Il valore dev\'essere minore di ' + maxValue);

        return(
            message.length > 0 &&
            <div className="flex light" style={{width: '100%', paddingTop: '5px'}}>
                <h6>{message}</h6>
            </div>
        )
    }

    let placeholder = '';
    let value2 = question;

    if(option.cod  === 'O126') {
            placeholder = day;
            value2 = day;
        if (day === '00') {
            placeholder = 'Giorno';
            value2 = '';
        }
    }else if (option.cod  === 'O127') {
        placeholder = month;
        value2 = month;
        if (month === '00') {
            placeholder = 'Mese';
            value2 = '';
        }
    }else{
        placeholder = year;
        value2 = year;
        if (year === '0000') {
            placeholder = 'Anno';
            value2 = '';
        }
    }

    const active = ((value2 >= min && value2 <= max) && (value2 !== '' && parseInt(value2) !== 0)) ? ' active' : '';
    const waiting = ((value2 < min || value2 > max) && (value2 !== '' && parseInt(value2) !== 0)) ? ' waiting' : ' ';


    useEffect(() => {
        
    })
    

    return(
        <>
            <div className={"flex y-a question-number " + active + waiting}  style={{boxSizing: 'boder-radius',  paddingRight: '15px', minHeight: '45px', width: 'auto', borderRadius: '5px', gap: '15px', paddingLeft: '15px'}}>
                <div>
                    <div  className="flex x-a y-a" style={{width: '30px', height: '30px', backgroundColor: 'var(--color5)', borderRadius: '100%'}}>
                        <img className="tick_icon" src={tick_icon} style={{width: '70%', filter: 'invert(1)'}}></img>
                    </div>
                </div>
                <div className="flex" style={{width: '100%', gap: '15px'}}>
                    <input placeholder={placeholder} value={value2} className="bold" onChange={(e) => dispatch(select(question.id, option.cod, e.target.value, user.id, table))}></input>
                </div>
            </div>
            <ErrorMessage 
            error={error}
            min={min}
            max={max}/>
        </>
    )
}