//icons
import { useDispatch } from 'react-redux';
import arrow from '../../media/icons/arrow.svg';

import { changePage } from '../../actions/pages';

function PageSelector(props) {

    const dispatch = useDispatch();

    const pages = props.pages;

    const chart_id = props.chart_id
    const page_id = props.page_id

    function change(page_id, chart_id, direction) {


      let page = parseInt(pages.page)

      if (direction === 'next') {

        if (page !== pages.pages) {
          page = (page < pages.pages ? (page + 1) : pages.pages);

        }else{
          page = 1;
        }

      }else{

        if (page !== 1) {
          page = (page > 1 ? (page - 1) : 1);
        }else{
          page = pages.pages;
        }

      }

      dispatch(changePage(page_id, chart_id, page));

    }

    return(
      <div className="flex y-a " style={{position: 'relive', gap: '10px', transform: 'scale(0.9)'}}>
        
        <div className="flex x-a y-a" style={{gap: '5px'}}>
          <div className="flex y-a x-a arrow-box pointer" onClick={() => change(page_id, chart_id, 'previous')}>
            <img className="arrow" src={arrow} direction="prev" style={{width: '15px', transform: 'rotate(90deg)'}}></img>
          </div>
          <div className="flex y-a x-a arrow-box pointer" onClick={() => change(page_id, chart_id, 'next')}>
            <img className="arrow" src={arrow} direction="next" style={{width: '15px', transform: 'rotate(-90deg)', paddingRight: '14px'}}></img>
          </div>
        </div>
        
        <div className="flex y-a x-a light" style={{gap: '15px'}}>
          <div className="flex x-a" style={{width: '45px'}}>
            <div style={{width: '22px'}}>
              <h5>{pages.page}</h5>
            </div>
            <h6> /&nbsp;</h6>
            <div style={{width: '26px'}}>
              <h5>{pages.pages}</h5>
            </div>
          </div>
        </div>
       
      </div>
    )
  }


export default PageSelector;