import { t } from '../../config/functions/translate';
import Background from '../../media/icons/layer6.svg';

export default function Banner(props) {

    const banner = props.banner;
    
    return(
        <div className="flex-column" style={{boxSizing: 'border-box', gridColumn: 'span 4', minHeight: '450px', height: 'auto', backgroundColor: '#222535', backgroundImage: "url(" + Background + ")", backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 'var(--widget--radius)', padding: '35px', paddingTop: '55px', gap: '20px'}}>
            <h2 style={{color: 'white'}}>{banner.title}</h2>
            {/* <h4 className="light" style={{color: 'white'}}>Ecco il tuo riepilogo</h4> */}
            <div style={{width: '89px', height: '2px', backgroundColor: 'var(--color3)'}}></div>
            <div>
                <h5 className="light" style={{color: 'white', lineHeight: '25.5px'}}>{t(banner.description)}</h5>
            </div>
        </div>
    )
}