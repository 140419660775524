const initialState = {
    visibility: 'none',
    status: 'none'
}

const Sidebar = (state = initialState, action) => {
    switch(action.type) {
        case 'sidebarStatus': 
        
            state.status = state.status !== 'fixed' ? 'fixed' : '';
            return state = {...state};
            
        case 'Sidebar': 
        
            state.visibility = state.visibility === 'none' ? 'visible' : 'none';
            return state = {...state};

        default:
            return state
    }
}

export default Sidebar;