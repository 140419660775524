import React from 'react';

import Menu from '../navbar/menu';
import Logo from '../navbar/logo';
import User from '../navbar/user';

function Navbar() {
        return (
                <div className="flex-column fixed y-a" style={{height: '100vh', width: 'var(--navbar--width)', backgroundColor: 'var(--color2)', gap: '15px', paddingTop: '15px'}}>
                        <Logo />  
                        <Menu /> 
                        <User />
                </div>
        )
}

export default Navbar;