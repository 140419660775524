import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "../../components/charts/chart";
import Toolbar from "../dashboard/toolbar";

import Setup from "./setup";

import { drag, fetchCharts } from "../../actions/pages";
import { useFetch } from "../../config/charts/usefetch";
import { deleteFilters } from '../../actions/pages';

import { saveData } from "../../actions";

import Banner from "../general/banner";
import Number from "../charts/number";
import Map from "../charts/map";

function Loading() {

    return(
        <>
        <div style={{height: '480px', gridColumn: '6 span', backgroundColor: 'var(--color5)', borderRadius: '20px'}}></div>
        <div style={{height: '480px', gridColumn: '6 span', backgroundColor: 'var(--color5)', borderRadius: '20px'}}></div>
        <div style={{height: '480px', gridColumn: '6 span', backgroundColor: 'var(--color5)', borderRadius: '20px'}}></div>
        <div style={{height: '480px', gridColumn: '6 span', backgroundColor: 'var(--color5)', borderRadius: '20px'}}></div>
        </>
    )
}


function Widget(props) {

    const chart = props.chart;
    const page = props.page;
    const index = props.index;
    
    return(
        <>
            {
                chart.type === 'map' &&

                <Map />
            }

            {
                chart.type === 'banner' &&

                <Banner 
                banner = {{
                    title: chart.title,
                    description: chart.description
                }}/> 
            }

            {
                chart.type !== 'banner' && chart.type !== 'number' && chart.type !== 'map' &&

                <Chart
                index={index}
                chart={chart}
                page={page}/> 
            }

            {
                chart.type === 'number' &&

                <Number
                index={index}
                chart={chart}
                page={page}/> 
            }
            

        </>
    )
}

export default function Body(props) {

    const dispatch = useDispatch();
   
    const page = props.page;

    const pages = useSelector(state => state.pages);

    const filters = useSelector(state => state.globalfilters);

    console.log(filters);


    useEffect(() => {
      
        // const body = new FormData();

        // body.append('page', page?.page_id);
        // body.append('token', localStorage.getItem('token'));
    
        // const url = 'https://api.sonardigital.it/format/format.php?api=charts';
        const url = props.url;

        const currentPage = pages?.find(item => item?.page_id === page?.page_id);
        
        if (currentPage?.charts === undefined) {
        fetch(url, {
            method: 'POST',
            heders: {
                'Accept': 'application/json',
                'Content-type':'application/json'
            },
            body: JSON.stringify({
                page: page?.page_id,
                token: localStorage.getItem('token')
            })
        }).then(response => {
            return response.json();

            }).then(data => {
                const array = data === undefined ? [] : data
                dispatch(fetchCharts(page.page_id, array))
            }).catch(error => {
                console.log(error);

                // console.log("Error: " + error);
            })
        }
    
        }, [page])


    const charts = props.page?.charts;

    const endpoints = useSelector(state => state.endpoints);

    const columns = 'repeat(12, 1fr)';

    const [draggedChart, setDrag] = useState();
    const [targetChart, setTargetChart] = useState();

    const search = useSelector(state => state.search).toLowerCase();

    function dragAndDrop() {

        if (draggedChart !== targetChart && targetChart !== undefined) {

            dispatch(drag(page.id, draggedChart, targetChart));
            
        }

        setDrag('');
        setTargetChart('');
        
    }

    const sidebar = useSelector(state => state.sidebar);
    
    useEffect(() => {
        if (sidebar.status !== 'fixed') {
            dispatch(deleteFilters());
        }

    }, [page?.id])

    return(
    <>
        <div className="flex-column absolute border-box" style={{boxSizing: 'border-box', width:'100%', height: '100%', top: 'var(--header--height)', padding: '35px', paddingTop: '10px', gap: '10px'}}>
                    
            <Toolbar />
        
            <div id="chart-body" className="chart-body" style={{paddingBottom: '50px', position: 'relative', gridTemplateColumns: columns, width: '100%', height: 'auto'}}>
                
                {
                    !charts?.length > 0 &&
                    <Loading />
                }

                {charts?.map((widget, index) => {
                        
                        const template = endpoints.find(template => template.cod === widget.cod);
                        const availableTemplates = template?.data?.data?.filter(data => data?.label?.toString().toLowerCase()?.includes(search?.toLowerCase()))
                        return(

                            (
                                widget.type.toLowerCase() === 'banner' ||
                                widget.title.toLowerCase().includes(search?.toLowerCase()) || 
                                template?.title?.toLowerCase().includes(search?.toLowerCase()) ||
                                template?.data?.data.map(obj => availableTemplates.includes(obj)).includes(true)
                            ) &&

                            <Widget 
                            key={index}
                            index={index}
                            chart={widget}
                            page={page}
                            drag={setDrag}
                            target={setTargetChart}
                            drop={dragAndDrop}
                            draggedChart={draggedChart}
                            targetChart={targetChart}/>

                    )

                })}
            </div>
            
        </div>
    </>
    )
}
