import { useDispatch } from "react-redux";
import { log } from "../../actions/login"

import user_icon from '../../media/icons/user.png';

export default function User() {
    
    const dispatch = useDispatch();

    const auth = sessionStorage.getItem('auth');
    
    return(
            // auth &&
            <div className="border-box" style={{position: 'absolute', bottom: '40px', left: '0px', width: '100%', height: 'auto', paddingLeft: '20px', paddingRight: '20px'}}>
                <div className="flex y-a" style={{width: '100%', height: '100%', borderTop: '1px solid var(--border--color)',  paddingTop: '8px', gap: '10px'}}>
                    <div className="flex x-a y-a pointer" style={{width: '40px', height: '40px', borderRadius: '50%', backgroundColor: 'var(--page--active)', overflow: 'hidden'}}>
                        <img className="light" src={user_icon} style={{width: 'auto', filter: 'invert(1)', opacity: '0.4', height: '70%', paddingBottom: '1px'}}></img>
                    </div>
                    <h5 className="color-white bold light pointer" onClick={() => dispatch(log('logout'))}>Logout</h5>
                </div>
            </div>
    )
}