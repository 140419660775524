import { useDispatch, useSelector } from 'react-redux';
import share_icon  from '../../media/icons/share.png';
import { toggleSidebar } from '../../actions';

import filter_icon from '../../media/icons/filters.svg';

function Tools(props) {

    const tools = [
        {title: 'Print', icon: share_icon},
        {title: 'Share', icon: share_icon},
        {title: 'Export', icon: share_icon},
        {title: 'Date picker', icon: share_icon}
    ]

    return(
        <>
        {tools.map((item, index) => {
            return(
                <div className="flex functions x-a y-a" key={index} style={{gap: '5px'}}>
                    <img src={item.icon} style={{width: 'auto', height: '30px'}}></img>
                    <h6 className="bold" style={{whiteSpace: 'nowrap'}} onClick={props.function}>{item.title}</h6>
                </div>
                
            )
        })}
        
        </>
    )

}

export default function Toolbar() {

    const filters = useSelector(state => state.globalfilters);
    const nodes = useSelector(state => state.nodes);

    const dispatch = useDispatch();
    
    return(
        Object.keys(filters).length > 0 &&
        <div className="flex y-a" style={{width: '100%', minHeight: '30px', paddingBottom: '3px'}}>
            <div className="flex y-a pointer" style={{width: '100%', gap: '8px'}} onClick={() => dispatch(toggleSidebar())}>
                <img src={filter_icon} style={{width: '20px'}}></img>
                {/* <h6 className="user-select">Dati aggiornati al: 04/05/2022</h6> */}
                {
                    
                    Object.keys(filters).map((key, index) => {

                        let filter = nodes.find(node => node.column === key)

                        return(
                            <>
                            <h4 className="bold user-select">{filter?.title}: </h4>
                            <div className="flex" style={{flexWrap: 'nowrap', gap: '3px'}}>
                                {
                                    filters[key].map((value, index2) => {
                                        let description = Object?.keys(filter?.nodes).find(a => a === value);
                                        description = filter.nodes[description]
                                        return(
                                            <h6 className="user-select" style={{paddingTop: '2px'}} key={index2}>{description} {filters[key].length !== (index2 + 1)&& '·'}</h6>
                                        )
                                    })
                                }
                            </div>
                            </>
                        )
                    })
                }
            </div>
            <div className="flex y-a pointer" style={{width: 'auto', justifyContent: 'end', gap: '4px'}}>
                {/* <Tools /> */}
            </div>
        </div>
    )
}