export const toggleSidebar = (column, value) => {
    
    return {
        type: 'Sidebar'
    }
}

export const saveData = (template, data) => {
    
    return {
        type: 'saveData',
        template: template,
        data: data
    }
}

export const deleteGroup = (column) => {

    return {
        type: 'deleteGroup',
        column: column
    }
}

export const toggleColumn = (column) => {

    return {
        type: 'toggleColumn',
        column: column
    }
}

export const sort = (obj) => {

    return {
        type: 'sort',
        obj: obj
    }
}

export const search = (value) => {

    return {
        type: 'search',
        value: value
    }
}

export const showNotification = (message) => {

    return {
        type: 'showNotification',
        message: message
    }
}

export const databaseSidebar = () => {

    return {
        type: 'databaseSidebar'
    }
}


export const databaseData = (data) => {

    return {
        type: 'databaseData',
        data: data
    }
}

export const toggleGroup = (group) => {

    return {
        type: 'toggleGroup',
        group: group
    }
}

export const changeTablePage = (page) => {

    return {
        type: 'changeTablePage',
        page: page
    }
}

export const saveUserSurvey = (data) => {

    return {
        type: 'saveUserSurvey',
        data: data
    }
}

export const addRecord = (section) => {

    return {
        type: 'addRecord',
        section: section
    }
}

export const options = (options, row, column, top, left) => {

    return {
        type: 'options',
        options: options,
        row: row,
        column: column,
        top: top,
        left: left
    }
}

export const toggleSection = (section) => {

    return {
        type: 'toggleSection',
        section: section
    }
}

export const changeFilters = (page, chart_id, filter_type, value) => {

    return {
        type: 'changeFilters',
        page: page,
        chart_id: chart_id,
        filter_type: filter_type,
        value: value

    }
}

export const toggleModule = (row) => {

    return {
        type: 'toggleModule',
        row, row

    }
}

export const changeTableDivider = (divider) => {

    return {
        type: 'changeTableDivider',
        divider: divider

    }
}
