import { useSelector } from "react-redux";
import { getTypes } from "../config/charts/types";

const path = 'https://api.sonardigital.it/format/format.php?api=';

const charts = getTypes();

const endpoints = [
    {
        endpoint: 'Empty',
        title: 'Configura il tuo grafico',
        config: {
            url: '',
            type: charts[0].name,
            title: 'Empty',
            sub_title: 'Grafico creato per il funzionamento della dashbaord Edge',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
     },{
        endpoint: 'AAA',
        title: 'Tipo di ospitalità',
        url: path + 'hospitality',
        type: charts[0].name,
        sub_title: 'Grafico creato per il funzionamento della dashbaord Edge',
        xaxisname: 'Labels',
        yaxisname: 'Values',
        numbersuffix: '%',
        filters: {}
     },{
        endpoint: 'AAB',
        title: 'Gestione pazienti',
        config: {
            url: path + 'patientmanagement',
            type: charts[3].name,
            title: 'Patient management',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
     },{
        endpoint: 'AAC',
        title: 'Qualifica dottori',
        config: {
            url: path + 'qualification',
            type: charts[0].name,
            title: 'Physician\'s qualification',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
     },{
        endpoint: 'AAD',
        title: 'Dipartimento',
        config: {
            url: path + 'department',
            type: charts[0].name,
            title: 'Department',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
     },{
        endpoint: 'AAE',
        title: 'Specializzazione',
        config: {
            url: path + 'specialization',
            type: charts[0].name,
            title: 'Specialization',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    },{
        endpoint: 'AAF',
        title: 'Genere pazienti',
        config: {
            url: path + 'specialization',
            type: charts[0].name,
            title: 'Genere pazienti',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    },
    {
        endpoint: 'AAH',
        title: 'Anno prima diagnosi',
        config: {
            url: path + 'yeardiagnosi',
            type: charts[0].name,
            title: 'Anno prima diagnosi',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    },
    {
        endpoint: 'AAI',
        title: 'Sample',
        url: path + 'geographicarea',
        type: charts[1].name,
        title: 'Sample',
        sub_title: '',
        xaxisname: 'Labels',
        yaxisname: 'Values',
        numbersuffix: '',
        filters: ''
    }
    ,{
        endpoint: 'AAL',
        title: 'Genere',
        config: {
            url: path + 'gender',
            type: charts[3].name,
            title: 'Genere',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'AAM',
        title: 'Hospital status',
        config: {
            url: path + 'hospitalstatus',
            type: charts[3].name,
            title: 'Hospital status',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'AAN',
        title: 'Motivo visita',
        config: {
            url: path + 'motivovisita',
            type: charts[0].name,
            title: 'Motivo visita',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'AAO',
        title: 'Referred patient',
        config: {
            url: path + 'referredpatient',
            type: charts[3].name,
            title: 'Referred patient',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'AAP',
        title: 'Diagnosi',
        config: {
            url: path + 'diagnosi',
            type: charts[3].name,
            title: 'Diagnosi',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'AAQ',
        title: 'Trapianti',
        config: {
            url: path + 'trapianti',
            type: charts[3].name,
            title: 'Trapianti',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'AAR',
        title: 'Take part in',
        config: {
            url: path + 'takepartin',
            type: charts[3].name,
            title: 'Take part in',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'AAS',
        title: 'Test fatti',
        config: {
            url: path + 'testfatti',
            type: charts[1].name,
            title: 'Test fatti',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'AAT',
        title: 'Età',
        config: {
            url: path + 'eta',
            type: charts[1].name,
            title: 'Età',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'AAU',
        title: 'Switch from',
        config: {
            url: path + 'switch',
            type: charts[1].name,
            title: 'Switch from',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'AAV',
        title: 'Skin direct',
        config: {
            url: path + 'skindirect',
            type: charts[1].name,
            title: 'Skin direct',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'AAZ',
        title: 'Systemic',
        config: {
            url: path + 'systemic',
            type: charts[1].name,
            title: 'Systemic',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'ABA',
        title: 'Share',
        config: {
            url: path + 'share',
            type: charts[1].name,
            title: 'Share',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'ABB',
        title: 'Regimens',
        config: {
            url: path + 'regimens',
            type: charts[2].name,
            title: 'Regimens',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
    ,{
        endpoint: 'ABC',
        title: 'Switch to',
        config: {
            url: path + 'switchto',
            type: charts[2].name,
            title: 'Switch to',
            sub_title: '',
            xaxisname: 'Labels',
            yaxisname: 'Values',
            numbersuffix: '%',
            filters: ''
        },
        filters: {}
    }
];

const Endpoints = (state = endpoints, action) => {

    switch(action.type) {
        
        case 'saveEndpoints':
            // const newFilters = {...action?.filters}
            state = action?.data;
            return state = [...state];
        case 'saveData':
                // const newFilters = {...action?.filters}
                const endpoint = state.find(template => template === action?.template);
                endpoint.data = action?.data;
                return state = [...state];
        default:
            return state
    }
}

export default Endpoints;