import Database from '../../components/table/database';

export default function Studies() {

    return(
        <Database 
        title='Studi clinici'
        sub_title='Database dottori'
        url='https://setup.therapyknowledge.com/endpoints/studies/read.php'/>
    )
}