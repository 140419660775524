import { useDispatch, useSelector } from "react-redux";
import Questions from "../questions";
import Question from "./question";
import { addQuestion } from "../../actions/survey";

export default function Section(props) {

    const dispatch = useDispatch()

    const section = props.section;
    const info = props.info;
    const user = props.user;
    const table = props.table;

    let number = 1;

    function Header(props) {
        const info = props.info;
        
        return(
            <div className="flex-column x-a" style={{boxSizing: 'border-box', width: '100%', paddingLeft: '36px', paddingRight: '36px', gap: '8px', marginTop: '40px', marginBottom: '23px'}}>
                <h2>{info.title}</h2>
                <h5 className="light bold"></h5>
            </div>
        )
    }
    
    return(
                    <div className="survey-section">
                        <Header 
                        info={info}/>
                        <div className="flex-column" style={{gap: '30px', width: '100%'}}>
                            {
                                section?.map((question, index) => {
                                    
                                    if (question.question_group === '') {
                                            return(
                                                <div key={index}>
                                                <Question
                                                number={number++}
                                                question={question}
                                                user={user}
                                                table={table}/>
                                                {
                                                    sessionStorage.getItem('role') === 'admin' &&
                                                    <div className="flex x-a y-a" style={{width: '100%', paddingTop: '27px'}}>
                                                        <button className="light bold pointer survey-btn" style={{}} onClick={() => dispatch(addQuestion(question))}>+ Add a question</button>
                                                    </div>
                                                }

                                                </div>
                                            )
                                        }else{
                                            //qui prima c'era questions al posto di section
                                            let option = section.filter(question2 => question2?.options?.find(option2 => option2?.condition?.includes(question.question_group)))[0]?.options?.find(option3 => option3?.condition?.includes(question.question_group))
                                            return(
                                                option?.status !== '' &&
                                                <div key={index}>
                                                    <Question 
                                                    number={number++}
                                                    question={question}
                                                    user={user}
                                                    table={table}/>
                                                    {
                                                        sessionStorage.getItem('role') === 'admin' &&
                                                        <div className="flex x-a y-a" style={{width: '100%', paddingTop: '27px'}}>
                                                            <button className="light bold pointer survey-btn" style={{}} onClick={() => dispatch(addQuestion(question))}>+ Add a question</button>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }
                                        
                                })
                            }
                        </div>

                    </div>
                )
            
}