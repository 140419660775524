import { useEffect, useState } from "react";
import Header from "../components/layout/header";

import Navbar from "../components/doctors/layout/navbar";
import Table from "../components/layout/table";

import { useDispatch, useSelector } from "react-redux";

import { databaseSidebar, databaseData } from "../actions";

import { addRecord } from "../actions";

import plus_icon from '.././media/icons/add.svg';

import ScrollToTop from "../config/functions/scrolltotop";

import { saveSurvey } from "../modules/survey/actions/survey";
import { useParams } from "react-router-dom";

import Questions from '../modules/survey/components/questions';


import Notification from "../modules/survey/components/notification";

export default function Physician(){

    const pageInfo = {
        title: 'Profile',
        sub_title: 'Pazienti aggiunti',
        tools: {
            searchbar: 0,
            sidebar: undefined
        }
    }

    const dispatch = useDispatch();
    
    const columns = 'repeat(10, 1fr)';

    const state = useSelector(state => state.database);

    const docid = '01';

    const data = useSelector(state => state.survey);

    const params = useParams();

    const [user, setUser] = useState({});
    
    useEffect(() => {

        const url = 'https://api.sonardigital.it/format/format.php?api=physicians_survey';
        
        if (Object.keys(user).length > 0) {
            fetch(url, {
                method: 'POST',
                heders: {
                'Accept': 'application/json',
                'Content-type':'application/json'
                },
                body: {}
                }).then(response => {
                return response.json();
                }).then(data => {
                    dispatch(saveSurvey(data?.data, user));
                }).catch(error => {
                // console.log("Error: " + error);
                }) 
        }

    }, [user])


    useEffect(() => {
        const url = 'https://api.sonardigital.it/format/format.php?api=physician_profile';
        const id = '1';
        const body = new FormData();
        body.append('id', id);
        fetch(url, {
            method: 'POST',
            heders: {
            'Accept': 'application/json',
            'Content-type':'application/json'
            },
            body: body
            }).then(response => {
              return response.json();
            }).then(data => {
                // dispatch(saveUserSurvey(data.data[0]));
                setUser(data.data[0]);
            }).catch(error => {
            // console.log("Error: " + error);
            }) 
    }, [])

    return(
        <>
        <Navbar />
      
            <div className="flex-column fixed border-box " style={{boxSizing: 'border-box', left: 'var(--navbar--width)', top: 'var(--header--height)', width: 'calc(100% - var(--navbar--width))', height: '100vh'}}>
                <Header 
                info={pageInfo}/>
            
                <div className="flex border-box" style={{boxSizing: 'border-box', width:'100%', height: 'calc(100% - 60px)', top: 'var(--header--height)', padding: '35px', paddingTop: '10px', gap: '10px', overflow: 'clip'}} >
                    <div className="flex" style={{boxSizing: 'border-box', paddingTop: '20px', paddingBottom: '20px', width: '30%'}}>
                        <div style={{width: '100%', height: '100%', backgroundColor: 'var(--color5)', borderRadius: '20px'}}></div>
                    </div>
                    <div className="flex" style={{boxSizing: 'border-box', width: '70%'}}>
                        <Questions 
                        user={user}
                        table='physicians'/>
                    </div>
                </div>
                <Notification />

            </div>

        </>
    )
};