

const initialState = {
    visibility: 'none'
}

const Window = (state = initialState, action) => {

    const page = action.page;
    const chart = action.chart;

    switch(action.type) {
        case 'visibility': 

            state.page = page;
            state.chart = chart;
            state.visibility = state.visibility === 'visible' ? 'none' : 'visible'
            return state = {...state};
        default:
            return state
    }
}

export default Window;