export function group(array, field) {

    let groups;

    if (array !== undefined) {
        groups = array?.reduce((x, y) => {
    
            (x[y[field]] = x[y[field]] || []).push(y);
    
            return x;
    
        }, {})
    }


    return groups;
}