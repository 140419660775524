import { useParams } from "react-router-dom";
import { uniqueId } from "../functions/uniqueid";

const initialState = [
    {
        id: '01',
        title: "Secondo lei, quale impatto ha sulla vita del paziente il linfoma cutaneo a cellule T?", 
        type: 'multiple choices',
        section: 'Anagrafica',
        group: '',
        caption: '',
        description: '',
        status: '',
        options: [
            {id: '01', title: 'Disturbi del sonnno', status: ''},
            {id: '02', title: 'Affaticamento/mancanza di energia', status: ''},
            {id: '03', title: 'Difficoltà con le attività di vita quotidiana', status: ''},
            {id: '04', title: 'Morale basso/depressione', status: ''},
            {id: '05', title: 'Preoccupazione/ansia', status: ''},
            {id: '06', title: 'Imbarazzo/sensazione di disagio in pubblico', status: ''}
        ]},
    {
        id:'02',
        title: "Questo paziente le è stato inviato da un altro specialista?", 
        type: 'single choices', 
        section: 'Questionario',
        group: '',
        caption: '',
        status: '',
        description: '', 
        options: [
            {id: '01', title: 'Si', status: '', condition: 'AAA'},
            {id: '02', title: 'No', status: '', condition: ''}
        ]},
    {
        id:'03',
        title: "Sintomi", 
        type: 'single choices', 
        section: 'Questionario',
        group: 'AAA',
        caption: '',
        status: '',
        description: '', 
        options: [
            {id: '01', title: 'Macchie, papule e/o placche limitate che coprono meno del 10% della superficie corporea (BSA)', status: '', condition: 'B'},
            {id: '02', title: 'Patch, papule e/o placche che coprono ≥ 10% di superficie corporea (BSA)', status: '', condition: 'B'},
            {id: '03', title: 'Malattia in stadio tumorale (uno o più tumori sulla pelle ≥ 1 cm)', status: '', condition: 'B'},
            {id: '04', title: 'Malattia eritrodermica (l\'area della superficie corporea è ≥ 80% rossa)', status: '', condition: 'B'},
            {id: '05', title: 'Coinvolgimento del sangue – B1 (cellule Sezary tra 250-999 cellule/uL)', status: '', condition: 'B'}
        ]},
    {
        id:'04',
        title: "Domanda n°4", 
        type: 'single choices', 
        section: 'Questionario',
        group: 'B',
        caption: '',
        status: '',
        description: '', 
        options: [
            {id: '01', title: 'AA', status: '', condition: ''},
            {id: '02', title: 'BB', status: '', condition: ''}
        ]}
];

const newState = [];

function updateRecord(id, question, table) {

    // fetch 
    const url = "https://setup.sonardigital.it/endpoints/survey/update.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        table: table,
        id: id,
        column: question.db_column,
        value: question.value,
    })
    }).then(response => {
      return response.json();
      }).then(data => {
      }).catch(error => {
        console.log("Error: " + error);
      })

}

function createRecords(obj) {

    // fetch 
    const url = "https://setup.therapyknowledge.com/endpoints/survey/create.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        cod: obj.cod,
        survey: obj.survey,
        title: obj.title,
        question_order: obj.question_order,
        type: obj.type,
        section: obj.section,
        options: 'O8'
    })
    }).then(response => {
      return response.json();
      }).then(data => {
      }).catch(error => {
        console.log("Error: " + error);
      })

}

function deleteQuestion(cod) {

    // fetch 
    const url = "https://setup.therapyknowledge.com/endpoints/survey/delete.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        cod: cod,
    })
    }).then(response => {
      return response.json();
      }).then(data => {
      }).catch(error => {
        console.log("Error: " + error);
      })

}

function updateValue(question, value, column) {

    // fetch 
    const url = "https://setup.therapyknowledge.com/endpoints/survey/update.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        cod: question.cod,
        column: column,
        value: value
    })
    }).then(response => {
      return response.json();
      }).then(data => {
      }).catch(error => {
        console.log("Error: " + error);
      })

}


function createOption(cod, value, options, title) {

    // fetch 
    const url = "https://setup.therapyknowledge.com/endpoints/survey/options/create.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        cod: cod,
        value: value,
        options: options,
        title: title
    })
    }).then(response => {
      return response.json();
      }).then(data => {
      }).catch(error => {
        console.log("Error: " + error);
      })

}

function createSection(cod, value) {

    // fetch 
    const url = "https://setup.therapyknowledge.com/endpoints/survey/section/create.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        cod: cod,
        value: value
    })
    }).then(response => {
      return response.json();
      }).then(data => {
      }).catch(error => {
        console.log("Error: " + error);
      })

}

function deleteOption(question, options, option) {

    // fetch 
    const url = "https://setup.therapyknowledge.com/endpoints/survey/options/delete.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        cod: question.cod,
        options: options,
        option: option,
    })
    }).then(response => {
      return response.json();
      }).then(data => {
      }).catch(error => {
        console.log("Error: " + error);
      })

}

function updateOptions(option, value, column) {

    // fetch 
    const url = "https://setup.therapyknowledge.com/endpoints/survey/options/update.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        cod: option.cod,
        column: column,
        value: value
    })
    }).then(response => {
      return response.json();
      }).then(data => {
      }).catch(error => {
        console.log("Error: " + error);
      })

}

const Survey = (state = [], action) => {
    const survey = {
        table: 'psoriasi'
    }
    let question = state.find(question => question.id === action.question_id);
    let options = question?.options;
    let option = options?.find(option => option.cod === action.option_id);

    const id = uniqueId();
    const cod = uniqueId();
    const question_order = Math.round((action?.question?.question_order + 1.0) * 1e12) / 1e12;
    const type = 'S1';
    const question_title = 'Titolo domanda';
    const section_cod = action?.question?.section_cod
    const section = action?.question?.section;

    const newQuestion = {
        id: id,
        cod: cod,
        survey_cod: action?.question?.survey_cod,
        survey: 'Psoriasi',
        title: question_title,
        question_order: question_order,
        question_group: '',
        status: '',
        type: type,
        section_cod: section_cod,
        section: section,
        type_caption: "Scelta singola",
        value: '',
        options: [ {
            cod: 'O8',
            title: "Si",
            status: ''
        }
        ]
    }

    switch(action.type) {
        case 'saveSurvey':
            state = action.data;

            state.map(question2 => { 
                question2.options.map(option2 => {
                    
                    option2.status = action.user[`${question2.db_column}`]?.includes(option2.cod) ? 'selected' : '';

                })
                question2.value = action.user[`${question2.db_column}`] !== undefined ? action.user[`${question2.db_column}`] : '';
                question2.status = (question2.value !== '' && question2.value !== '0000-00-00') ? 'saved' : '';
            })
            
                // if (question2.type === 'S1' || question2.type === 'S2' || question2.type === 'S5') {
                //     let option2 = question2.options.find(option => option.cod === action.user[question2.db_column]);

                //     if (option2 !== undefined || option2 !== 'undefined') {
                //         option2.status = 'selected';
                //     }

                // }else{
                //     if (action.user[`${question2.db_column}`] !== undefined || action.user[`${question2.db_column}`] !== 'undefined') {
                //         question2.value = action.user[`${question2.db_column}`]
                //     }else{
                //         question2.value = "";
                //     }
                    
                // }
           

            
            return state = [...state];
        case 'saveUserSurvey':

            return state = [...state];
        case 'select':

            if (question.type === 'S2' || question.type === 'S5') {
                options.map(option => option.status = '');
            }
            
            option.status = option.status !== '' ? '' : 'selected';

            if (options.map(option => option.status === 'selected').includes(true)) {
                question.status = 'saved'
            }else{
                question.status = ''
            }


            if (question.type === 'S1') {
                const array = options.map(option => {return option.status === 'selected' && option}).filter(item => item !== false);

                const selectedOptions = array.map(option => {
                    return option.cod;
                }).join('&');


                question.value = selectedOptions;
            }else if (question.type === 'S4' || question.type === 'S3') {

                if (action.value === '') {
                    question.status = '';
                    delete question.value;
                }else{
                    question.status = 'saved';
                    question.value = action.value;
                }

            }else if (question.type === 'S7') {

                let value = (question.value !== '') ? question.value : '0000-00-01';

                value = value.split('-')

                let year = value[0];
                let month = value[1];
                let day = value[2];

                if (option.cod === 'O126') {
                    if (action.value !== '') {
                        day = action.value;
                    }else{
                        day = value[2];
                    }
                }else if (option.cod === 'O127') {
                    if (action.value !== '') {
                        month = action.value;
                    }else{
                        month = value[1];
                    }
                }else{
                    if (action.value !== '') {
                        year = action.value;
                    }else{
                        year = value[0];
                    }
                }

                let newValue =  year + "-" + month + "-" + day

                    if (value === '0000-00-00') {
                        question.status = 'saved';
                    }else{
                        question.status = 'saved';
                    }
                    
                //controllo differenza date
                if (question.check_question !== '') {
                    let newQuestion = state.find(question2 => question2.cod === question.check_question);
                    if (newQuestion.value !== '') {
                         

                        function dateDiffInDays(a, b) {
                            let date1 = new Date(a);
                            let date2 = new Date(b);
                              
                            // To calculate the time difference of two dates
                            let Difference_In_Time = date2.getTime() - date1.getTime();
                              
                            // To calculate the no. of days between two dates
                            let Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
                            return Difference_In_Days
                          }
                       
                          if (dateDiffInDays(newQuestion.value, newValue) > parseInt(question.check_value)) {
                            // console.log(dateDiffInDays(newQuestion.value, newValue.toString()))
                            // newValue = '0000-00-00';
                            question.error = 'La data dev\'essere entro sei mesi dalla data della terapia attuale';
                          }else{
                            question.error = '';
                          }
                    }
                }

                    question.value = newValue;

            }else{

                    question.status = 'saved';
                    question.value = option.cod;
                
            }

            updateRecord(action.id, question, action.table)

            // console.log(question.value);


            let red = '';

            for (let i = 0; i < state.length; i++) {

                    if (state.reverse()[i].status == 'saved') {
                        red = 'yes'
                    }else{
                        if (red === 'yes') {
                        state[i].status = 'waiting';
                        }

                    }

                // if (red != '') {
                //     if (state.reverse()[i].status !== 'completed') {
                //         state.reverse()[i].status = '';
                //     }
                // }

                state.reverse();


            }

            // const index = state.indexOf(question) + 1

            // for (let i=0; i< state.length; i++) {

            //     const question = state[i];

            //     if (i < index) {
            //         // console.log(question);
            //     }else{
            //         question.status = '';
            //         question?.options.map(option => {option.status = ''});
            //     }
            // }

            return state = [...state];
        case 'addQuestion':

            const id = uniqueId();
            const cod = uniqueId();
            const question_order = Math.round((action.question.question_order + 1.0) * 1e12) / 1e12;
            const type = 'S1';
            const question_title = 'Titolo domanda';

            const newQuestion = {
                id: id,
                cod: cod,
                survey_cod: action.question.survey_cod,
                survey: 'Psoriasi',
                title: question_title,
                question_order: question_order,
                question_group: '',
                status: '',
                type: type,
                section_cod: action.question.section_cod,
                section: action.question.section,
                type_caption: "Scelta singola",
                value: '',
                options: [ {
                    cod: 'O8',
                    title: "Si",
                    status: ''
                }
                ]
            }

            createRecords({
                id: id,
                survey: action.question.survey_cod,
                cod: cod,
                title: question_title,
                question_order: question_order,
                type: type,
                section: action.question.section_cod,
            })
            
            return state = [...state, newQuestion];
        case 'addOptions':

        let newCode = uniqueId().slice(5).toString().replace('&','')

            const newOption = {
                cod: newCode,
                title: 'Titolo',
                status: ''
            };

            question.options = [...question.options, newOption];

            createOption(newCode, 'Nuova opzione', question.options.map(question => question.cod).join('&'), question.cod)
            
            return state = [...state];
        case 'updateValue':

            updateValue(action.question, action.value, 'italian');

            question[action.field] = action.value;
            
            return state = [...state];
        case 'deleteQuestion':
            state = state.filter(question => question.cod !== action.cod);
            deleteQuestion(action.cod);
            return state = [...state];
        case 'updateOption':
            updateOptions(option, action.value, 'italian');     
            option.title = action.value;    
            return state = [...state];
        case 'deleteOption':

            // question.options = [...question.options, newOptions];


            const array = options.map(option => option.cod).flat()
            const index = array.indexOf(option.cod)
            array.splice(index, 1);

            deleteOption(question, array.join('&'), option.cod);

            // split option
            // remove
            //join question.options.map(question => question.cod).join('&'), question.cod)
            // function

            // createOption(newCode, 'Nuova opzione', question.options.map(question => question.cod).join('&'), question.cod)
            
            return state = [...state];
        default:
            return state
    }
}

export default Survey;