export function sortBy(data, sorting) {
    
    document.getElementById('table')?.scroll({
        top: 0, 
        left: undefined, 
        behavior: 'smooth'
      });
      
    let sortedData = data?.sort(function(a,b){
       
        let x = a[sorting.field]
        let y = b[sorting.field]

        
        if (/^\d+$/.test(a[sorting.field])) {
            x = parseInt(a[sorting.field])
            y = parseInt(b[sorting.field])
        }

        if (x === null || x === '') {
            return 1;
        }
        
        if (y === null || y === '') {
            return -1;
        }
        
        if (x === y) {
            return 0;
        }
    
        // if(x>y){return 1;} 
        // if(x<y){return -1;}
        // return 0;

        
        
        return x > y ? 1 : -1;
    });

    sortedData = sorting.direction !== 'DESC' ? sortedData : sortedData.reverse();

    return sortedData;
        
}