const GlobalFilters = (state = {}, action) => {
    
    switch(action.type) {
        case 'pageFilter': 

            if (state[action.column] !== undefined) {

                if (!state[action.column].includes(action.value)) {
                    state[action.column].push(action.value);
                }else{

                    if (state[action.column].length > 1) {
                        state[action.column] = state[action.column].filter(value => value !== action.value);
                    }else{
                        delete state[action.column]
                    }
                }
                
            }else{
                state[action.column] = [action.value]
            }

            return state = {...state};
        case 'sliderFilter': 

            state[action.column] = [];
            if (state[action.column] !== undefined) {

                if (!state[action.column].includes(action.value)) {
                    state[action.column].push(action.value);
                }else{

                    if (state[action.column].length > 1) {
                        state[action.column] = state[action.column].filter(value => value !== action.value);
                    }else{
                        delete state[action.column]
                    }
                }
                
            }else{
                state[action.column] = [action.value]
            }

            return state = {...state};

        case 'deleteFilters': 

            const newState = {}
            return state = newState;

        case 'deleteGroup': 

            delete state[action.column];
            return state = {...state};
            
        default:
            return state;
    }
}

export default GlobalFilters;