
import Database from '../../components/table/database';

export default function Patients() {

    return(
        <Database 
        title='Patients'
        sub_title='Database pazienti'
        url='https://setup.therapyknowledge.com/endpoints/patients/read.php'
        body={JSON.stringify({
            token: localStorage.getItem('token')
        })}/>
    )
}