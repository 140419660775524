import { useDispatch, useSelector } from "react-redux"
import { t } from "../functions/translate";
import { useState } from "react";
import { toggleModule } from "../actions/table";

function Section(props) {

    const row = props.row;
    const column = props.column;

    return(
        <div className="flex-column" style={{width: '100%', gap: '10px'}}>
            <h4 className="bold">{column[0]?.toUpperCase() + column?.slice(1)?.toLowerCase()}</h4>
            <input value={props.value} onInput={(e) => console.log(e.target.value)} style={{border: '1px solid var(--border--color)', background: 'none', boxSizing: 'border-box', padding: '10px', margin: '0', borderRadius: '5px', height: '40px', fontSize: '16px'}}></input>
            {/* <div style={{width: '100%', height: '1px', backgroundColor: 'var(--border--color)'}}></div> */}
        </div>
    )
}

export default function Details() {

    const state = useSelector(state => state.database);

    const dispatch = useDispatch();

    const module = state.module;
    const row = state.module.data;

    const padding = '20px';

    return(
        <div style={{position: 'absolute', right: 0, top: 0, width: '550px', height: '100%', backgroundColor: 'white', borderLeft: '1px solid var(--border--color)', zIndex: 600, display: (state.module.visibility === 'visible' ? 'block' : 'none')}}>
            <div className="flex y-a" style={{width: '100%'}}>
                <div className="flex user-select" style={{boxSizing: 'border-box', padding: padding, width: '70%'}}>
                    <h3 style={{padding: '0', lineHeight: '24px'}}>{t('Dettagli')}</h3>
                </div>
                <div className="flex user-select " style={{boxSizing: 'border-box', padding: padding,width: '30%', justifyContent: 'end'}}>
                    <h4 className="pointer" onClick={() => dispatch(toggleModule(''))}>{t('Close')}</h4>
                </div>
            </div>
            <div style={{width: '100%', height: '1px', backgroundColor: 'var(--border--color)'}}></div>
            <div className="flex-column" style={{boxSizing: 'bprder-box', padding: padding, width: 'auto', height: '100%', overflow: 'scroll', gap: '25px', boxSizing: 'border-box', paddingBottom: '150px'}}>
                {
                    Object.keys(row)?.map((column, index) => {
                        return(
                            <Section
                            key={index}
                            column={column}
                            value={row[column]}
                            row={row} />
                        )
                    })
                }
                
            </div>
        </div>
    )
}