import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop(props) {

    const container = props.container;

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById('chart-body')?.scrollTo(0, 0);
    }, [pathname])

    return null;

}