
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

import { useSelector } from "react-redux";

import { useFetch } from "../../config/charts/usefetch.js";
import { pageDefine, pagination } from "../../config/charts/pagination.js";
import { Toolbar } from "./toolbar.js";

import empty_chart_icon from '../../media/icons/charts.svg';

import { t } from '../../config/functions/translate.js';


ReactFusioncharts.fcRoot(FusionCharts, FusionTheme);

charts(FusionCharts);

function Title(props) {

  const title = props.info.title
  const caption = props.info.description
  const total = props.info.total;

  return(
    <div className="flex-column" style={{gap: '2px', paddingTop: '13px'}}>
        <h4 className="chart-text bold" style={{textAlign: 'center', fontSize: '19px'}}>{t(title)}</h4>
        <h6 className=" light" style={{textAlign: 'center', lineHeight: '18px'}}>{total}</h6>
        {/* <h6 className=" light" style={{textAlign: 'center'}}>{caption}</h6> */}
    </div>
  )
}

function Cover() {
  return(
      <div className="flex x-a y-a" style={{width: '100%', height: '100%'}}>
        <div className="layer" style={{position: 'absolute', backgroundColor: 'var(--layer)', width: '100%', height: '100%', zIndex: '35'}}></div>
        <img src={empty_chart_icon} style={{width: '270px', height: '80%'}}></img>
      </div>
  )
}

export default function Chart(props) {
  const chart = props.chart; 

  const templates = useSelector(state => state.endpoints);
  const template = templates?.find(template => template.cod === chart?.cod);

  const page = props.page;

  const data = ((chart?.data === undefined) ? template?.data : chart?.data);

    // chart.filters = {regione: ['8&9&12&17']}

  useFetch(chart, page);

  const pages = pageDefine(data?.data?.length, chart?.divider, (chart?.page !== 'undefined' ? chart.page : 1));

  const graph = pagination(data?.data, pages);

  const span = 'span ' + chart.size;

  const total = (data?.total !== undefined && data?.total !== null) ? ('Base ' + (data?.total || '')) : ''

  const info = {
    title: (chart?.title !== '' && chart?.title !== undefined) ? chart?.title : template?.title,
    total: total
  }

  const colors = (chart.type === 'pie2d' || chart.type === 'doughnut2d') ? 'DAB44D,  484D62, 838D9C, 5E64A1, 838D9C, 7EB1AB' : '5594B6';

  const values = (chart.type === 'column2d' || chart.type === 'bar2d') ? 1 : 0;
  
  const labels = (chart?.type === 'column2d' || chart.type === 'bar2d' || chart.type === '') ? 1 : 0;

  const dataSource = {
    chart: {
      animation: 0,
      animationDuration: 0.6,
      caption: '',
      subcaption: '',
      xaxisname: '',
      yAxisMaxValue: (chart.limit !== '' ? chart.limit : ''),
      yaxisname: '',
      palettecolors: colors,
      numbersuffix: template?.numbersuffix,
      showvalues: values,
      labelFontColor: '00000',
      enableSmartLabels: 0,
      showLabels: labels,
      showLegend: 1,
      showYAxisValues: 0,
      showYAxisLine: 0,
      bgColor: '#f7f7f7',
      decimals: 1,
      baseFont: "Inter",
      baseFontSize: "13.5",
      labelDisplay: 'AUTO',
      creditLabel: false,
      numVisibleLabels: 12,
      exportEnabled: true,
      chartTopMargin: 0,
      chartBottomMargin: 10,
      theme: "fusion",
    },
    data: graph
  };

    return (
      <>
      
        <div className="chart flex-column border-box "
        draggable={true} 
        onDragStart={(e) => {props.drag(chart);}}
        onDragOver={(e) => {e.preventDefault(); props.target(chart);}} 
        onDragEnd={(e) => props.drop()} 
        // style={{position: 'relative', width: '100%', gridColumn: span, padding: '15px', paddingTop: '5px',  paddingBottom: '10px', border: ((props.draggedChart !== chart && props.targetChart !== chart) ? '1px solid var(--border--color)' : '1px solid var(--color7)'), backgroundColor: 'var(--color5)', backgroundColor: 'white', borderRadius: 'var(--widget--radius)', overflow: 'hidden'}}>
        style={{position: 'relative', width: '100%', gridColumn: span, padding: '15px', paddingTop: '5px', backgroundColor: 'var(--color5)', borderRadius: 'var(--widget--radius)', overflow: 'hidden'}}>
        
        <div className="flex-column y-a x-a" style={{width: '100%', minHeight: '75px',  gap: '1px'}}>
       
        {
          (props.targetChart === chart && props.draggedChart !== chart) &&
          <div style={{position: 'absolute', width: '100%', top: '0', left: '0', backgroundColor: 'var(--color7)',  zIndex: '80'}}></div>
          
        }
             
          <Title 
          info={info}/> 

          </div>
            
          <div className="flex-column x-a" style={{position: 'relative', width: '100%', overflow: 'hidden', borderRadius: '10px'}}>

            {
              (template?.data === undefined) ? 

              // <Cover />
              <div style={{width: '100%', height: '320px'}}></div>
              :
                <ReactFusioncharts
                type={ chart.type !== '' ? chart.type : template.type }
                width="100%"
                height="320"
                dataFormat="JSON"
                dataSource={dataSource}
                />
              
            }           
            {
            <Toolbar 
            tools_visibility='none'
            page={page}
            chart={chart}
            template={template}
            pages={pages}
            size={chart.size}
            data={graph}/>

          }
          </div>
          
          

        </div>

      </>
    );
}