import { getTypes } from "../config/charts/types";

const initialState = getTypes();

const Charts = (state = initialState, action) => {
    switch(action.type) {
        default:
            return state
    }
}

export default Charts;