const initialState = {
    visibility: 'none',
    message: ''
};

const Notification = (state = initialState, action) => {
    switch(action.type) {
        case 'showNotification': 

            state = {
                visibility: 'visible',
                message: action.message
            };

            return state = {...state}
        default:
            return state;
    }
}

export default Notification;