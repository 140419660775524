import { useDispatch, useSelector } from "react-redux";

import { firstToUpperCase } from '../../../config/functions/firsttouppercase';

import tick_icon from '../../../media/icons/tick.png';
import { group } from "../../../config/functions/group";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { addQuestion, addOptions, select } from "../actions/survey";

import { showNotification } from "../../../actions";
import PageSelector from "./questions/pageselector";
import Section from "./questions/section";


export default function Questions(props) {

    const questions = props?.questions;

    const table = props.table;

    const sections = props.sections;
    const section = props.section;

    const index = sections.indexOf(section);

    const setKey = props.setKey;

    const [page = {
        index: index,
        pages: sections.length
    }, setPage] = useState();

    function changePage(direction) {


        if (direction === 'indietro') {
            if (page.index == 0) {
                return
            }else{
                page.index = (page.index - 1)
                setKey(sections[page.index])
            }
        }else{
            if (page.index == (sections.length - 1)) {
                return
            }else{
                page.index = (page.index + 1)
                setKey(sections[page.index])
            }

        }
        

    }

    const user = props.user;
    
    return(
        <div id="questions" className="questions flex-column y-a" style={{width: '100%', boxSizing: 'border-box', height: '100%', overflow: 'scroll', gap: '45px', margin: '0', paddingBottom: '35px'}}>
            {
                [sections[page.index]]?.map((section, index) => {

                    const info = {
                        title: section,
                        subtitle: ''
                    }
                    
                    return(
                            <Section
                            key={index}
                            info={info}
                            section={questions} 
                            user={user}
                            table={table}
                            />
                    )
                })
            }
            {
                sections.length > 1 &&
                <PageSelector 
                changePage={changePage}
                page={page}/>
            }
        </div>
    )
}