import { useEffect, useState } from "react";
import Header from "../layout/header";

import Navbar from "../doctors/layout/navbar";
import Table from "./components/table";

import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./components/sidebar";

import { databaseSidebar, databaseData } from "../../actions";

import { Export } from "./functions/exportxlsx";

import { t } from "./functions/translate";

import export_icon from '../../media/icons/excel icon.png';

export default function Datasabe(props){

    const pageInfo = {
        title: props.title,
        sub_title: props.sub_title,
        tools: {
            sidebar: databaseSidebar
        }
    }

    const dispatch = useDispatch();

    const state = useSelector(state => state.database);

    useEffect(() => {

        const url = props.url;

            fetch(url, {
                method: 'POST',
                heders: {
                'Accept': 'application/json',
                'Content-type':'application/json'
                },
                body: props.body
                }).then(response => {
                return response.json();
                }).then(data => {
                    dispatch(databaseData(data));
                }).catch(error => {
                // console.log("Error: " + error);
                })
    }, [])

    return(
        <>
        <Navbar />
      
            <div className="flex-column fixed border-box " style={{boxSizing: 'border-box', left: 'var(--navbar--width)', top: 'var(--header--height)', width: 'calc(100% - var(--navbar--width))', height: '100vh'}}>
                <Header 
                info={pageInfo}/>
            
            <div className="flex-column border-box" style={{boxSizing: 'border-box', width:'100%', height: 'calc(100% - 90px)', top: 'var(--header--height)', padding: '35px', paddingTop: '10px', gap: '10px', overflow: 'clip'}}>
                <div className="flex" style={{width: '100%',  height: '40px'}}>
                    <div className="flex y-a" style={{width: '80%', height: '100%', justifyContent: 'start', gap: '5px'}}>
                        <h6>{t('Risultati trovati')}: {state.data.length}</h6>
                    </div>
                    <div className="flex y-a" style={{width: '20%', height: '100%', justifyContent: 'end', gap: '15px'}}>
                        <div className="flex x-a y-a pointer tool-btn" onClick={(e) => Export(state.data, pageInfo?.title)} style={{width: '100px', paddingLeft: '8px', paddingRight: '8px'}}>
                            <img src={export_icon} style={{width: '32px', height: 'auto'}}></img>
                            <h6 className="bold">Esporta</h6>
                        </div>
                    </div>
                </div>

                <Table />
            </div>

            <Sidebar 
            data={state.data}/>

        </div>
    </>
    )
};