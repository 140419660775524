import { useEffect, useState } from "react"
import Questions from "../survey/components/questions";
import Sidebar from "../survey/components/sidebar";
import { useDispatch, useSelector } from "react-redux";

import { saveSurvey } from "./actions/survey";

import { useParams } from "react-router-dom";

import { style } from './style/style.css';

import Notification from "./../survey/components/notification";
import { group } from "../../config/functions/group"; // * da cambiare


export default function Survey() {

    const dispatch = useDispatch();
    const params = useParams();


    const data = useSelector(state => state.survey);
    const [user, setUser] = useState({});

    const survey = params.survey;

    useEffect(() => {

        const url = 'https://setup.therapyknowledge.com/endpoints/survey/read.php';
        
        if (user !== 'undefined') {
            fetch(url, {
                method: 'POST',
                heders: {
                'Accept': 'application/json',
                'Content-type':'application/json'
                },
                body: JSON.stringify({
                    survey: survey
                })
                }).then(response => {
                return response.json();
                }).then(data => {
                    if (user !== 'undefined') {
                    dispatch(saveSurvey(data, user));
                    }
                }).catch(error => {
                // console.log("Error: " + error);
                }) 
        }

    }, [user])


    // useEffect(() => {
    //     const url = 'https://api.sonardigital.it/format/format.php?api=database';
        
    //     const body = new FormData();
    //     body.append('pid', params.id);
    //     fetch(url, {
    //         method: 'POST',
    //         heders: {
    //         'Accept': 'application/json',
    //         'Content-type':'application/json'
    //         },
    //         body: body
    //         }).then(response => {
    //           return response.json();
    //         }).then(data => {
    //             // dispatch(saveUserSurvey(data.data[0]));
    //             setUser(data.data[0]);
    //         }).catch(error => {
    //         // console.log("Error: " + error);
    //         }) 
    // }, [])

    const sections = group(data, 'section');

    const [section = Object?.keys(sections)[0], setKey] = useState();

    useEffect(() => {
        document.getElementById('questions').scrollTo(0,0)
    }, [section])


    return(
        <div className="flex x-a" style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100vh'}}>
            <div className="survey-questions">
                <Questions 
                questions={sections[section]}
                user={user}
                setKey={setKey}
                sections={Object?.keys(sections)}
                section={section}
                table='psoriasi'/>
            </div>
            <Notification />
            <Sidebar 
            setKey={setKey}
            section={section}/>
        </div>
    )
}