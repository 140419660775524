import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filters from '../setup/filters';
import { t } from '../../config/functions/translate';

import pin_icon from '../../media/icons/pin.svg';

import { sidebarStatus } from '../../actions';

function Sidebar() {

    const [nodes, setNodes] = useState()

    const dispatch = useDispatch();

    const [keys, setKeys] = useState()
    const sidebar = useSelector(state => state.sidebar);

    useEffect(() => {

        const url = "https://api.sonardigital.it/format/format.php?api=nodes";

        fetch(url).then(response => {
            return response.json();
            }).then(data => {
            data.map(data => data.visibility = 'none')

            const groups = data?.reduce((x, y) => {

                (x[y.group] = x[y.group] || []).push(y);
        
                return x;
        
            }, {})

            setNodes(groups);

            setKeys(Object.keys(groups))


            }).catch(error => {
            console.log("Error: " + error);
            })
      
    }, [])

    // const width = '450px';
    const width = '550px';

    const light = 'light';

    const ref = useRef();

    return(
        <div className={"sidebar " + (sidebar.visibility !== 'none' ? 'active' : '') + " absolute"} style={{backgroundColor: 'white', borderLeft: '1px solid var(--border--color)', right: '0', width: width, height: '100%',  zIndex: '10', paddingTop: 'var(--header--height)', zIndex: '80'}}>
            <div ref={ref} style={{overflow: 'auto', height: '85%', paddingTop: '15px', paddingBottom: '100px'}}>
                <div className="flex-column border-box" style={{width: '100%', paddingLeft: '28px', paddingRight: '28px', paddingBottom: '18px'}}>
                <div style={{width: '100%', paddingTop: '10px'}}>
                    <h2 style={{padding: '0', lineHeight: '24px'}}>{t('Filtri')}</h2>
                </div>
                <div className={"flex y-a pointer " + (sidebar.status !== 'fixed' && 'light')} style={{width: '100%', paddingTop: '20px', gap: '10px'}}>
                    <img src={pin_icon} style={{height: '35px'}}></img>
                    <div>
                        <h5 className={"bold " + (sidebar.status !== 'fixed' && 'light')} onClick={() => dispatch(sidebarStatus())}>Pin {sidebar.status !== 'fixed' ? '(off)' : '(on)'}</h5>
                        <h6 className="light" onClick={() => dispatch(sidebarStatus())}>Mantieni i filtri attivi tra le pagine</h6>
                    </div>
                </div>

                <Filters />
                
                </div>
            </div>
        </div>        
    )
}

export default Sidebar;