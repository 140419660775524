import Database from '../../components/table/database';

export default function Hospitals() {

    return(
        <Database 
        title='Hospitals'
        sub_title='Database ospedali'
        url='https://api.therapyknowledge.com/format/format.php?api=database'/>
    )
}