import { useDispatch, useSelector } from "react-redux"

import { toggleColumn } from "../../../actions";
import { useState } from "react";

import { databaseSidebar } from "../../../actions";

import { t } from "../functions/translate";
import { changeTableDivider, toggleGroup } from "../actions/table";

function Title(props) {

    const title = props.title;

    return(
        <div style={{height: '32px', borderBottom: '1px solid var(--border--color)', marginBottom: '5px'}}>
            <h3 className="user-select bold">{t(title)}</h3>
        </div>
    )
}

export default function Sidebar(props) {

    const dispatch = useDispatch();

    const state = useSelector(state => state.database);

    const data = (props?.data !== undefined ? props.data : []);

    const keys = data.length > 0 ? Object?.keys(data[0]) : [];

    const visibility = state.sidebar.visibility === 'visible' ? 'block' : 'none';

    let timeout;

    function getPagination(e) {

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            dispatch(changeTableDivider(e.target.value))
          }, "800");
    }

    return(
        <div className="flex-column" style={{position: 'absolute', boxSizing: 'border-box', right: '0', top: '0', width: '350px', height: '100%', backgroundColor: 'white', zIndex: '450', padding: '35px', borderLeft: '1px solid var(--border--color)', display: visibility, overflow: 'scroll'}}>
                {/* <h4 onClick={() => dispatch(databaseSidebar())}>Close</h4> */}
                    <Title
                    title={'Colonne'} />
                    <div className="flex-column" style={{boxSizing: 'border-box', width: '100%', height: 'auto', overflow: 'scroll', paddingBottom: '15px', paddingTop: '10px', gap: '8px'}}>
                        {
                            keys?.map((column, index) => {
                                return(
                                    <div className="pointer" key={index} >
                                        <h5 className={"user-select option " + (state.columns.includes(column) ? 'active' : '')} onClick={() => dispatch(toggleColumn(column))}>{column}</h5>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <Title
                    title={'Raggruppa per'} />
                    <div className="flex-column" style={{boxSizing: 'border-box', width: '100%', height: 'auto', overflow: 'scroll', paddingBottom: '15px', paddingTop: '10px', gap: '8px'}}>
                        {
                            keys?.map((column, index) => {
                                return(
                                    <div className="pointer" key={index} >
                                        <h5 className={"user-select option  " + (state.group === column ? 'active' : '')} onClick={() => dispatch(toggleGroup(column))}>{column}</h5>
                                    </div>
                                )
                            })
                        }
                    </div>
                    
                    <Title
                    title={'Paginazione'} />
                    <div className="flex-column" style={{boxSizing: 'border-box', width: '100%', height: 'auto', overflow: 'scroll', paddingBottom: '15px', paddingTop: '10px', gap: '8px'}}>
                    <h5 className="user-select">{t('Elementi per pagina')}</h5>
                        <div className="pointer">
                            <input type="number" defaultValue="100" style={{width: '99%', border: '1px solid var(--border--color)', background: 'none', borderRadius: '3px', height: '38px', fontSize: '16px', boxSizing: 'border-box', paddingLeft: '10px', paddingRight: '10px'}} onInput={(e) => getPagination(e)}></input>
                        </div>
                    </div>
            </div>
    )
}