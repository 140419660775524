import arrow from '../../../../media/icons/arrow.svg';

export default function PageSelector(props) {

    const changePage = props.changePage;
    const page = props.page;

    return(
        <div className="flex x-a" style={{width: '100%'}}>

        <div className="flex y-a " style={{position: 'relive', gap: '10px', transform: 'scale(0.9)'}}>
    
        <div className="flex x-a y-a" style={{gap: '5px'}}>
        <div className="flex y-a x-a arrow-box pointer" onClick={() => changePage('indietro')}>
            <img className="arrow" src={arrow} direction="prev" style={{width: '15px', transform: 'rotate(90deg)'}}></img>
        </div>
        <div className="flex y-a x-a arrow-box pointer" onClick={() => changePage('avanti')}>
            <img className="arrow" src={arrow} direction="next" style={{width: '15px', transform: 'rotate(90deg)', transform: 'rotate(270deg) scaleX(-1)'}}></img>
        </div>
        </div>
        
        <div className="flex y-a x-a " style={{gap: '15px'}}>
        <div className="flex x-a" style={{width: '45px'}}>
            <div style={{width: '22px'}}>
            <h5 className="bold">{(page?.index + 1)}</h5>
            </div>
            <h6> /&nbsp;</h6>
            <div style={{width: '26px'}}>
            <h5 className="bold">{page?.pages}</h5>
            </div>
        </div>
        </div>
    
        </div>
    </div>
    )
}