import Header from "../components/layout/header";

import { usePage } from "../config/functions/usepage";
import Navbar from "../components/layout/navbar";

import Overview from "../components/layout/oveview";

import Banner from "../components/general/banner";
import { toggleSidebar } from "../actions";
import { cipher } from "../config/functions/cipher";
import map_image from "../media/icons/Mappa Italia.png";
import Map from "../components/charts/map";

function Home(){

    const pageInfo = {
        title: 'Home',
        tools: {
            searchbar: 0,
            sidebar: undefined
        }
    }

    const columns = 'repeat(10, 1fr)';

    const banner = {
        title: 'Psorasi',
        description: "Benvenuti in Therapy Knowledge: un sistema epidemiologico internazionale che monitora i trattamenti medici. Collezioniamo un alto volume di dati clinici e reports in modo costante permettendo una valutazione in tempo reale dei trattamenti correnti."
    }

    return(
        <>
        <Navbar />

        <div className="flex fixed border-box " style={{boxSizing: 'border-box', left: 'var(--navbar--width)', top: 'var(--header--height)', width: 'calc(100% - var(--navbar--width))'}}>
            <Header 
            info={pageInfo}/>
            
            <div className="flex border-box" style={{boxSizing: 'border-box', display: 'grid', gridTemplateColumns: columns, gridAutoRows: 'min-content', width:'100%', height: '100%', top: 'var(--header--height)', padding: '35px', paddingTop: '24px', gap: '20px'}}>

                <Overview />

                <Map />

                <Banner 
                banner={banner}/>

            </div>
        </div>
    </>
    )
}

export default Home;