import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { select } from "../../actions/survey";
import { showNotification } from "../../../../actions";
import tick_icon from '../../../../media/icons/tick.png';
import Option from "./option";
import Text from "./text";
import Number from "./number";
import Date from "./date";

export default function Options(props) {

    const question = props.question;
    const options = question.options;

    const user = props.user;
    const table = props.table;

    const flex = (question.type === 'S5' || question.type === 'S7') ? 'flex' : 'flex-column'


    return(
        <div className={flex + " options pointer"} style={{boxSizing: 'border-box', width: '100%', height: '100%', gap: '8px'}}>
            {
                options?.map((option, index) => {

                    if (question.type === 'S1' || question.type === 'S2') {
                        return(
                            <Option
                            key={index}
                            question={question}
                            option={option}
                            table={table}
                            user={user}/>
                        )
                    }else if(question.type === 'S4') {
                        return(
                            <Text
                            key={index}
                            question={question}
                            option={option}
                            table={table}
                            user={user}/>
                        )
                    }else if(question.type === 'S7' ) {
                        return(
                            <Date
                            key={index}
                            question={question}
                            option={option}
                            table={table}
                            user={user}/>
                        )
                    }else{
                        return(
                            <Number
                            key={index}
                            question={question}
                            option={option}
                            table={table}
                            user={user}/>
                        )
                    }
                    
                })
            }   
                
                
            
        </div>
    )
}

