import { useDispatch, useSelector } from "react-redux";
import * as XLSX from 'xlsx/xlsx';
import { module } from "../../actions/window";

import filters_icon from '../../media/icons/filters.svg';
import setup_icon from '../../media/icons/setup.svg';
import export_icon from '../../media/icons/excel icon.png';
import heart_icon from '../../media/icons/heart.svg';

import PageSelector from "./pageselector";
import { useParams } from "react-router-dom";

export function Toolbar(props) {

  const dispatch = useDispatch();

  const setup = useSelector(state => state.window);

  const data = props.data;

  const chart = props.chart;
  const page = props.page;

  const pages = props.pages;

  function Export(data, title) {
    if (data !== undefined) {
        
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data, {
        header: [title],
      });

      XLSX.utils.book_append_sheet(wb, ws, title);

      XLSX.writeFile(wb, "MyExcel.xlsx");

    }

  }

  const currentPage = useParams().id;

    return(
      <>
      {
        setup.visibility !== 'visible' && 
      
      <div className="flex" style={{width: '100%'}}>
        <div className="flex y-a" style={{width: '75%'}}  >
          {
            pages.pages > 1 &&
            <PageSelector 
            pages={pages}
            chart_id={chart.id}
            page_id={page.page_id}/>
          }
          {
            
            (chart.filters !== undefined && ((chart.filters !== undefined ? (Object.keys(chart.filters).length) : '') > 0)) &&
            
            <div className="flex x-a y-a pointer light tool-btn active" style={{position: 'absolute', left: '3px', width: '80px', height: '25px', gap: '10px'}} onClick={(e) => dispatch(module('visibility', page, chart))}>
              <img className="" src={filters_icon} style={{width: '18px', height: '55%'}}></img>
              <h6 className="bold">Filters</h6>
            </div>
          }
          
        </div>
          
        <div className="flex x-a" style={{width: '25%', justifyContent: 'end', gap: '5px'}}>
          {/* <button className="btn btn-hover pointer" style={{fontSize: '14px', height: '25px', display: 'block', borderRadius: '15px'}}>Export</button> */}
        </div>

        {
            // sessionStorage.getItem('role') === 'admin' &&
            window.location.pathname.split('/')[1] === 'my-dashboard' &&
            <div className="flex x-a y-a pointer tool-btn" onClick={(e) => dispatch(module('visibility', page, chart))} style={{width: '35px'}}>
              <img src={setup_icon} style={{width: '15px'}}></img>
            </div>
        }

        {
          data?.length > 0 &&
          <div className="flex" style={{transform: 'scale(0.9)'}}>
            {/* <div className="flex x-a y-a pointer tool-btn" style={{width: '35px', paddingLeft: '5px', paddingRight: '5px'}}>
              <img src={heart_icon} style={{width: '22px', height: 'auto'}}></img>
            </div> */}
            
            <div className="flex x-a y-a pointer tool-btn" onClick={(e) => Export(data, chart?.title)} style={{width: '35px', paddingLeft: '5px', paddingRight: '5px'}}>
              <img src={export_icon} style={{width: '35px', height: 'auto'}}></img>
              <h6 className="bold"></h6>
            </div>
          </div>
        }
        
        {/* <button className="btn btn-hover pointer" onClick={(e) => dispatch(window('visibility', page, chart))} style={{width: '70px', fontSize: '14px', height: '25px', display: 'block', borderRadius: '15px'}}>Setup</button> */}

      </div>
      }
  
      </>
  )
  
    
  }