import React, { useEffect, useRef, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { toggleClass } from '../../config/functions/toggleclass';

import { toggleSidebar } from '../../actions';
import { addChart, changePageTitle } from '../../actions/pages';
import { searchInput } from '../../actions/search';

import filters_icon from '../../media/icons/filters.svg';
import add_icon from '../../media/icons/add.svg';
import heart_icon from '../../media/icons/heart.svg';
import { t } from '../../config/functions/translate.js';
import { Link, useParams } from 'react-router-dom';
import { usePage } from '../../config/functions/usepage';

function Title(props) {

    const dispatch = useDispatch();

    const page_id = useParams()?.id;

    const page = usePage(page_id);

    // useEffect(() => {

    //     const timer = setTimeout(() => {
    //   console.log("here")
    //         dispatch(changePageTitle(page_id, title));
    //     }, 800)
    
    //     return () => clearTimeout(timer)
    //   }, [])

    return(
        <input className="my-dashboard" style={{fontSize: '20px', lineHeight: '26px', fontWeight: '600', padding: '0', margin: '0'}} value={t(page?.title)} onInput={(e) => dispatch(changePageTitle(page_id, e.target.value))}></input>
    )
}


function Header(props) {
        
        const dispatch = useDispatch();

        const title = props.info.title
        const sub_title = props.info.sub_title
        const page = props.info.page

        const visibility = (title === 'Home' ? 'none' : 'flex');

        function Tools() {

            const sidebar = useSelector(state => state.sidebar);
            const search_text = useSelector(state => state.search);
            const database = useSelector(state => state.database);

            return(
                <div className="flex y-a" style={{width: '60%', minWidth: '500px', gap: '5px', justifyContent: 'end'}}>
                    {
                        props.info.tools.searchbar !== 0 &&
                        <input className="search-input pointer" value={search_text} style={{height: '30px', paddingLeft: '15px', borderRadius: '20px', paddingRight: '15px', marginTop: '8px', fontSize: '16px'}} placeholder="Cerca nella dashboard" onInput={(e) => dispatch(searchInput(e.target.value))}></input>
                    }
                        {/* <button className="btn pointer btn-hover" style={{width: 'auto', whiteSpace: 'nowrap', padding: '8px', paddingLeft: '15px', borderRadius: '20px', paddingRight: '15px', fontSize: '16px'}} onClick={() => dispatch(addChart(page.id))}>Add a chart</button> */}
                    {/* {
                        <Link to="/favourite/demografia">
                            <div className={"tool-btn flex x-a y-a pointer" + ((3>5) ? " active" : '')} style={{width: '25px', height: '25px'}}>
                                <img src={heart_icon} style={{width: '21px'}}></img>
                            </div>
                        </Link>
                    } */}
                    {
                        window.location.pathname.split('/')[1] === 'my-dashboard' &&
                        <div className="tool-btn flex x-a y-a pointer" style={{width: '25px', height: '25px'}} onClick={() => dispatch(props.info.tools.add(page.page_id))}>
                            <img src={add_icon} style={{width: '20px'}}></img>
                        </div>
                    }
                    {
                        props.info.tools.sidebar !== undefined &&
                        <div className={"tool-btn flex x-a y-a pointer user-select " + ((sidebar.visibility === 'visible' || database.sidebar.visibility !== 'none') ? " active" : '')} style={{width: '25px', height: '25px'}} onClick={() => dispatch(props.info.tools.sidebar())}>
                            <img src={filters_icon} style={{width: '20px'}}></img>
                        </div>
                    }
                    
                    {/* <button className="btn pointer btn-hover" style={{padding: '8px', paddingLeft: '15px', borderRadius: '20px', paddingRight: '15px', fontSize: '16px'}} onClick={(e) => {dispatch(toggleSidebar()); toggleClass(e)}}>Filters</button> */}
                </div>
            )
        }
        
        return(
            <div className="titleBar flex y-a white fixed" style={{boxSizing: 'border-box', paddingLeft: '35px', paddingRight: '35px', width: 'calc(100% - var(--navbar--width))', height: 'var(--header--height)', borderBottom: '1px solid var(--border--color)', zIndex: '50', gap: '20px'}}>
                
                <div className="flex-column x-a" style={{width: '60%', height: '100%', justifyContent: 'center', gap: '1px'}}>
                    {
                        window.location.pathname.split('/')[1] === 'my-dashboard' ?
                        <Title
                        title={title} />
                        :
                        <h2 style={{fontSize: '20px', lineHeight: '26px', cursor: 'default'}}>{t(title)}</h2>

                    }
                    <h5 className="light pointer" style={{}}>{t(sub_title)}</h5>
                </div>

                <Tools />
               
            </div>
        )
}

export default Header;