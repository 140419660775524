import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function Notification() {

    const notification = useSelector(state => state.notification)

    const [visibility, setVisibility] = useState('none');
    const [count, setCount] = useState(0);

    let timeout = null;

    useEffect(() => {
        if (count > 0) {
            setVisibility('visible')

            clearTimeout(timeout);
            timeout = setTimeout(function () {
                setVisibility('none')
            }, 800);
        }

        setCount(1);
        

    }, [notification])

    return(
        visibility === 'visible' &&
        <div style={{position: 'fixed', left: '20px', bottom: '40px'}}>
            <h4 className="bold">Saved</h4>
        </div>
    )
}
