import { useDispatch, useSelector } from 'react-redux';

import { group } from '../../../config/functions/group';

import logo from '../../../media/icons/logo.png';
import { createSection } from '../actions/survey';

function Logo() {
    return(
        <div className="flex y-a x-a" style={{position: 'relative', width: '100%', height: '120px', top: '20px'}}>
            <img src={logo} style={{width: '130px'}}></img>
        </div>
    )
}

function StatusBar() {

    const questions = useSelector(state => state.survey);

    // Map tutte le domande una per una
    // quando stato === saved return true
    // quando diverso 
    
    // console.log(
    //     questions.filter(question => 
    //         {
    //             if (question.status !== 'waiting') {
    //                 return true
    //             }
    //         }
    //     )
    // );

    return(
        ''
    )
}

function Index(props) {

    const questions = useSelector(state => state.survey);

    const sections = group(questions, 'section');

    const keys = Object.keys(sections);

    const dispatch = useDispatch();

    const setKey = props.setKey;
    const section = props.section;

    let number = 1;

    return(
        <div className="flex-column user-select" style={{gap: '15px'}}>
            <h3 className="color-white">Naviga tra le sezioni:</h3>
            <div className="flex-column" style={{gap: '5px'}}>
                
            {
                keys?.map((key, index) => {
                    return(
                        <div className={"flex pointer" + (section !== key ? ' light' : '')} key={index} onClick={() => setKey(key)}>
                            <div className="flex x-a" style={{minWidth: '45px'}}>
                                <h4 className="bold" style={{color: 'white'}}>{number++}</h4>
                            </div>
                            <h4 className="bold" style={{color: 'white'}}>{key}</h4>
                        </div>
                    )
                })
            }
            </div>
            {/* <h4 style={{color: 'white'}} onClick={() => dispatch(createSection())}>Add</h4> */}
        </div>
    )
}

function Save() {

    return(
        <div className={"flex x-a y-a pointer save"} style={{width: '75%', minWidth: '310px', height: '50px', margin: 'auto', marginBottom: '25px', borderRadius: '8px'}} onClick={(e) => e.currentTarget.classList.add('active')}>
            <h4 className="bold" style={{textAlign: 'center'}}>SAVE</h4>
        </div>
    )
}

export default function Sidebar(props) {

    const setKey = props.setKey;
    const section = props.section;
    
    return(
        <div className="survey-sidebar" style={{width: '33%', height: '100%', backgroundColor: 'var(--color2)'}}>
            <div style={{width: '100%', height: '50%'}}>
                <Logo />
            </div>
            <div style={{boxSizing: 'border-box', width: '100%', height: '50%', paddingLeft: '25px', paddingRight: '25px'}}>
                <StatusBar /> 
                <Index 
                setKey={setKey}
                section={section}/>
            </div>

            <Save />
        </div>
    )
}