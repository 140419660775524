import { useDispatch, useSelector } from "react-redux"
import { useActionData } from "react-router-dom";

import { module } from "../../actions/window";

import { chartTitle, changeType, changeEndpoint, removeChart, changeDivider, changeLimit, reSize } from "../../actions/pages";

import Chart from "../charts/chart";
import { useEffect } from "react";

import Filters from "../setup/filters";

function Title(props) {

    const title = props.title

    return(
        <div className="flex-column" style={{gap: '3px'}}>
            <h4 className="bold user-select">{title}</h4>
        </div>
    )
}


function Type(props) {

  const dispatch = useDispatch();

  const template = props.template
  const chart = props.chart
  const page = props.page
  const chart_types = useSelector(state => state.charts)

  const type = chart?.type !== '' ? chart?.type.name : template?.config.type.name;

  return(
    <div className="flex-column pointer" style={{gap: '8px'}}>
        <Title 
        title={'Type'} />

        <div className="flex pointer" style={{gap: '5px'}}>
            {
            chart_types?.map((type, index) => {
                return(
                <div  key={index} className={"type-icons-div flex x-a y-a" + (type.name === chart.type ? ' active' : '')} onClick={(e) => dispatch(changeType(page.page_id, chart.id, type.name))}>
                    <img src={type.icon} style={{height: 'auto', width: '90%'}}></img>  
                </div>
                )
            })
            }
        </div>
    </div>
  )
} 


function Size(props) {

    const dispatch = useDispatch();
    const chart = props.chart
    const page = props.page
  
    return(
        <div className="flex-column" style={{gap: '8px'}}>
            <Title 
            title={'Size'} />

            <div className="flex pointer" style={{height: '53px', gap: '6.5px'}}>
                <div className={"size-icon " + (chart.size === 4 ? ' active' :  '')} style={{width: '32px'}}  onClick={(e) => dispatch(reSize(page.page_id, chart.id, 4))}></div>
                <div className={"size-icon " + (chart.size === 5 ? ' active' :  '')} style={{width: '42px'}}  onClick={(e) => dispatch(reSize(page.page_id, chart.id, 5))}></div>
                <div className={"size-icon " + (chart.size === 6 ? ' active' :  '')} style={{width: '60px'}}  onClick={(e) => dispatch(reSize(page.page_id, chart.id, 6))}></div>
                <div className={"size-icon " + (chart.size === 10 ? ' active' :  '')} style={{width: '68px'}}  onClick={(e) => dispatch(reSize(page.page_id, chart.id, 10))}></div>
            </div>
      </div>
    )
  }
  

function Endpoint(props) {

    const dispatch = useDispatch();

    const chart = props.chart;
  
    const endpoints = useSelector(state => state.endpoints);
    const page = props.page;

     
  
    return(
        <div className="flex-column" style={{gap: '8px'}}>
            <Title 
            title={'Endpoint'} />
        
            <select value={chart.cod} onChange={(e) => dispatch(changeEndpoint(page.page_id, chart.id, e.target.value))}
            style={{border: '1px solid var(--border--color)', fontSize: '16px', paddingLeft: '10px', width: '100%'}}>
            {
                endpoints?.sort((a, b) => {
                    const nameA = a?.title?.toUpperCase(); // ignore upper and lowercase
                    const nameB = b?.title?.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                  
                    // names must be equal
                    return 0;
                  }).map((grafico, index) => {
                return(
                    <option key={index} value={grafico.cod}>{grafico.title}</option>
                )
                })
            }
            </select>
            </div>
    )
  } 



function Pagination(props) {

    const chart = props.chart;
    const page = props.page;

    const dispatch = useDispatch();

    return(
        <div className="flex-column" style={{gap: '5px'}}>
            <Title 
            title={'Elementi per pagina'} />
            <input className="light" type="number" value={chart.divider} onInput={(e) => dispatch(changeDivider(page.page_id, chart.id, e.target.value))}></input>
        </div>
    )
}

function Remove(props) {

    const dispatch = useDispatch();

    const page = props.page;
    const chart = props.chart;


    return(
        <div className="flex-column" style={{gap: '5px'}}>
        {/* <Title 
        title={'Cancella il grafico'} /> */}
        <h4 className="pointer" style={{color: 'var(--color3)'}} onClick={(e) => {dispatch(removeChart(page.page_id, chart.id)); dispatch(module('visibility'))}}>Remove the chart</h4>
    </div>
    )
}

function ChartTitle(props) {

    const chart = props.chart;
    const page = props.page;
    const template = props.template;

    const dispatch = useDispatch();

    let timeout = null;
       
    function getInput(e) {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
        dispatch(chartTitle(page.page_id, chart?.id, e.target.value))
        }, 1000)
    }

    return(
        <div style={{}}>
        <Title 
        title={'Title'} />

        <input defaultValue={chart?.title} placeholder={template?.title} onInput={(e) => getInput(e)}></input>
        </div>
    )
}


function Limit(props) {

    const dispatch = useDispatch();

    const chart = props.chart;
  
    const page = props.page;
  
    return(
        <div className="flex-column" style={{gap: '5px'}}>
            <Title 
            title={'Valore massimo'} />
            <input className="light" type="number" value={chart.limit} placeholder="20" onInput={(e) => dispatch(changeLimit(page.page_id, chart.id, e.target.value))}></input>
        </div>
    )
  } 

export default function Setup() {

    const templates = useSelector(state => state.endpoints);
    const window = useSelector(state => state.window);

    const dispatch = useDispatch();

    const page = window?.page
    const chart = window?.chart
    const template = templates?.find(template => template.cod === chart.cod)

    const title = chart?.title !== '' ? chart?.title : template?.title
    
    return(
        <div  id="window" className="flex x-a y-a" style={{position: 'fixed', boxSizing: 'border-box', display: (window?.visibility === 'visible' ? 'flex' : 'none'), top: '0', right: '0', zIndex: '500', width: 'calc(100% - var(--navbar--width)', height: '100vh'}}>
                <div className="flex x-a y-a" style={{backgroundColor: 'white', width: '100%', height: '100%'}}>
                    <div id="chart-setting" className="flex" style={{position: 'relative', width: '88%', height: '76%', backgroundColor: 'white', border: '1px solid var(--border--color)', borderRadius: '20px', overflow: 'hidden'}}>
                        <div className="flex x-a y-a" style={{width: '65%', height: '100%', backgroundColor: 'var(--color5)', borderRight: '1px solid var(--border--color)'}}>
                            <div style={{width: '95%'}}>
                                <Chart 
                                chart={chart}
                                page={page}
                                template={template}/>
                            </div>
                        </div>
                        <div className="flex-column border-box" style={{width: '35%', height: '100%', overflowY: 'scroll', paddingBottom: '20px'}}>
                            <div className="user-select" style={{backgroundColor: 'white', zIndex: '60', width: '100%',  position: 'sticky', top: '0'}}>
                                <div className="flex border-box" style={{width: '100%', height: '55px', alignItems: 'flex-end', paddingBottom: '10px',  paddingLeft: '25px', paddingRight: '25px', borderBottom: '1px solid var(--border--color)'}}>
                                    <div style={{width: '80%'}}>
                                        <h2>Chart setup</h2>
                                    </div>
                                    <div style={{width: '20%', paddingBottom: '2px'}}>
                                        <h4 className="light pointer" onClick={() => dispatch(module('visibility'))} style={{}}>Close</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-column border-box" style={{gap: '18px', paddingTop: '20px', paddingLeft: '25px', paddingRight: '25px'}}>
                                <ChartTitle
                                chart={chart}
                                page={page}
                                template={template}/>

                                <Endpoint 
                                chart={chart}
                                template={template}
                                page={page}/>

                                <Type
                                chart={chart}
                                page={page}/>

                                <Limit
                                chart={chart}
                                page={page}/>

                                <Pagination
                                chart={chart}
                                page={page}/>

                                <Size
                                chart={chart}
                                page={page}/>

                                <div>
                                    <h3>Filtri</h3>
                                    <Filters
                                    chart={chart}
                                    template={template}
                                    page={page}/>
                                </div>

                                <Remove
                                chart={chart}
                                page={page}/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}