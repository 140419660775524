const initialState = {};

const Home = (state = initialState, action) => {
    switch(action.type) {
        case 'addValue': 
            state[action.parameter] = action.value;
            return state = {...state};
        default:
            return state
    }
}

export default Home;