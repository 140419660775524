

export const fetchCharts = (page, data) => {
    
    return {
        type: 'fetchCharts',
        page: page,
        data: data
    }
}

export const changeEndpoint = (page, chart_id, endpoint) => {
    
    return {
        type: 'changeEndpoint',
        endpoint: endpoint,
        page: page,
        chart_id: chart_id
    }
}

export const changeType = (page, chart_id, chart_type) => {

    return {
        type: 'changeType',
        chart_type: chart_type,
        page: page,
        chart_id: chart_id
    }
}

export const addChart = (page) => {

    const newChart = {
        id: Math.random(),
        title: '',
        endpoint: 'Empty',
        type: 'column2d'
    }

    return {
        type: 'addChart',
        page: page,
        chart: newChart
    }
}

export const reSize = (page, chart_id, size) => {

    return {
        type: 'reSize',
        page: page,
        chart_id: chart_id,
        size: size
    }
}

export const deleteFilters = (page, chart_id) => {

    return {
        type: 'deleteFilters',
        page: page,
        chart_id: chart_id
    }
}

export const saveChartData = (page, chart_id, data) => {

    return {
        type: 'saveChartData',
        page: page,
        chart_id: chart_id,
        data: data
    }
}

export const changePageTitle = (page, value) => {

    return {
        type: 'changePageTitle',
        page,
        value
    }
}

export const removeChart = (page, chart_id) => {

    return {
        type: 'remove',
        page: page,
        chart_id: chart_id
    }
}

export const addPages = (group) => {

    return {
        type: 'addPages',
        group: group
    }
}

export const changeDivider = (page, chart, value) => {

    return {
        type: 'changeDivider',
        page: page,
        chart_id: chart,
        value: value
    }
}

export const changeLimit = (page, chart, value) => {

    return {
        type: 'changeLimit',
        page: page,
        chart_id: chart,
        value: value
    }
}
export const changeTitle = (page, text) => {

    return {
        type: 'changeTitle',
        page: page,
        text: text
    }
}

export const drag = (page, chart, target) => {

    return {
        type: 'drag',
        page: page,
        chart: chart,
        target: target
    }
}


export const changePage = (page, chart, value) => {

    return {
        type: 'changePage',
        page: page,
        chart_id: chart,
        value: value
    }
}

export const chartTitle = (page, chart, value) => {

    return {
        type: 'chartTitle',
        page: page,
        chart_id: chart,
        value: value
    }
}

export const fetchPages = (data) => {

    return {
        type: 'fetchPages',
        data: data
    }
}