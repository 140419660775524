import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./multiRangeSlider.css";
import { useDispatch } from "react-redux";
import { deleteGroup, pageFilter, saveFilters, saveRangeFilters, sliderFilter } from "../../actions";
import { useParams } from "react-router-dom";

const MultiRangeSlider = ({ min, max, onChange, db_column, options, chart, template }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(minVal);
  const maxValRef = useRef(maxVal);
  const range = useRef(null);

  const dispatch = useDispatch();

  const page_id = useParams().id;

  useEffect(() => {
    let timer1 = setTimeout(() => {
        if (minValRef.current > min || maxValRef.current < maxVal) {
            dispatch(sliderFilter(db_column, "'" + minValRef.current + "'&'" + maxValRef.current + "'"));
            if (3>2) {
              dispatch(saveRangeFilters(page_id, chart?.id, db_column, "'" + minValRef.current + "'&'" + maxValRef.current + "'", template))
            }

        }else{
          if (minValRef.current == min && maxValRef.current == max) {
              dispatch(deleteGroup(db_column));
          }
        }

    }, 1 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [minVal, maxVal])

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  return (
    <div className="container">
      <div className="slider">

      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        className="thumb thumb--right"
      />

        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="flex" style={{width: '100%', height: '45px', paddingTop: '15px'}}>
            <div className="flex" style={{width: '50%', height: '100%'}}>
                <h4 className="bold"><span className="light">{min}</span> /{minValRef.current}</h4>
            </div>
            <div className="flex" style={{width: '50%', height: '100%', justifyContent: 'end'}}>
                <h4 className="bold">{maxValRef.current} / <span className="light">{max}</span></h4>
            </div>
        </div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MultiRangeSlider;
