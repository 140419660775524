import { useDispatch } from "react-redux";
import { firstToUpperCase } from "../../../../config/functions/firsttouppercase"; //copiare
import { addOptions, deleteQuestion } from "../../actions/survey";
import Options from "./options";
import { t } from "../../functions/translate";
import { useEffect, useRef, useState } from "react";


import { updateValue } from "../../actions/survey";


export default function Question(props) {

    const dispatch = useDispatch();

    const number = props.number;
    const question = props.question;

    const type = firstToUpperCase(question?.type);

    const user = props.user;

    const table = props.table;

    const waiting = (question.status?.toLowerCase() === 'waiting' || question.error?.length > 0)  ? ' waiting' : '';

    let timeout;

    const ref = useRef();

    function inputValue(question, value, field) {
        dispatch(updateValue(question, value, field));
        clearTimeout(timeout);
        timeout = setTimeout(() => {
        }, 1000)
    }



    return(
        <div className={"flex-column question-box" + waiting} style={{boxSizing: 'border-box', position: 'relative', borderRadius: '14px', paddingTop: '20px', gap: '15px'}}>
            <div className="flex x-a" style={{width: '100%'}}>
                <div className="flex y-a" style={{width: '75%', height: '40px', gap: '14px'}}>
                    <div className="flex x-a y-a" style={{width: '25px', height: '25px', backgroundColor: 'var(--color2)', borderRadius: '100%'}}>
                        <h6 className="color-white bold" style={{fontSize: '14px'}}>{number}</h6>
                    </div>
                    <div>
                        <h6 className="light bold">Status: {firstToUpperCase(question.status)}</h6>
                    </div>
                </div>
                <div className="flex y-a" style={{width: '35%', justifyContent: 'flex-end', gap: '14px'}}>
                    <div>
                        <h6 className="light bold" style={{whiteSpace: 'nowrap'}}>{t(question.type)}</h6>
                    </div>
                    <div className="flex x-a y-a m-hide" style={{width: '25px', height: '25px', backgroundColor: '#D5D5D5', borderRadius: '100%'}}>
                    </div>
                </div>

            </div>
            
            <div style={{width: '100%'}}>
            {
                sessionStorage.getItem('role') === 'admin' ?
                <div className="flex-column x-a y-a" style={{width: '100%', gap: '5px'}}>
                    <input ref={ref} value={question.title} className="bold" placeholder="Titolo domanda" onInput={(e) => inputValue(question, e.target.value, 'title')} style={{border: 'none'}}></input>
                </div>
                :
                <h3 className="bold">{question.title}</h3>
            }
                {/* <input defaultValue={question.title} className="bold" style={{border: 'none', padding: '0'}}></input> */}
                <h5 className="light" style={{marginTop: '10px'}}>{question.error}</h5>
                
            </div>

            <Options
            question={question}
            user={user}
            table={table}/>
            {
                sessionStorage.getItem('role') === 'admin' &&
                <div className="flex-column x-a y-a" style={{width: '100%', gap: '5px'}}>
                    <button className="light bold pointer survey-btn" onClick={() => dispatch(addOptions(question.id))}>+ Add an option</button>
                    <button className="light bold pointer survey-btn" onClick={() => dispatch(deleteQuestion(question.cod))}>Remove</button>
                </div>
            }

        </div>
    )
}