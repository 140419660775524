import * as XLSX from 'xlsx/xlsx';

export function Export(data, table) {
    if (data !== undefined) {
        
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);

      XLSX.utils.book_append_sheet(wb, ws, table);

      XLSX.writeFile(wb, "Edge.xlsx");

    }

  }