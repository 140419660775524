import { combineReducers } from 'redux';

import nodes from './nodes';
import filters from './filters';
import globalfilters from './pagefilters';
import home from './home';
import charts from './charts';
import sidebar from './sidebar';
import endpoints from './endpoints';
import database from '../components/table/reducers/database';
import window from './window';
import notification from './notification';
import pages from './pages';
import search from './search';
import login from './login';
import survey from '../modules/survey/reducers/survey';

const rootReducer = combineReducers({
    filters: filters,
    nodes: nodes,
    home: home,
    globalfilters: globalfilters,
    charts: charts,
    login: login,
    survey: survey,
    database: database,
    notification: notification,
    sidebar: sidebar,
    window: window,
    search: search,
    endpoints: endpoints,
    pages: pages
})

export default rootReducer;