import logo from "../../media/icons/logo.png";

import { Link } from "react-router-dom";

export default function Logo() {
    return (
        <Link to="/">
            <div className="flex pointer x-a d-w100 border-box" style={{padding: '10px'}}>         
                {/* <img className="logo" src={logo} style={{width: '50%', height: 'auto'}}></img> */}
                <img className="logo" src={logo} style={{width: '50%', height: 'auto'}}></img>
            </div>
        </Link>
    )
}
