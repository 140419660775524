import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../../actions";
import { select } from "../../actions/survey";
import tick_icon from '../../../../media/icons/tick.png';
import { useParams } from "react-router-dom";

export default function Number(props) {

    const dispatch = useDispatch();

    const question = props.question;
    const option = props.option;

    const table = props.table;

    const min = option.min;
    const max = option.max;

    const [error, setError] = useState();

    const user = props.user;

    const active = ((question.value >= min && question.value < max) && (question.value !== '' && parseInt(question.value) !== 0)) ? ' active' : '';
    const waiting = ((question.value <= min || question.value > max) && (question.value !== '' && parseInt(question.value) !== 0)) ? ' waiting' : ' ';
    let timeout = null;

    const ref = useRef()

    clearTimeout(timeout);

    useEffect(() => {


    }, [question.value])

   

    function ErrorMessage(props) {

        const minValue = props.min;
        const maxValue = props.max;
        let message = '';

        question.value<minValue && (message='Il valore inserito dev\'essere maggiore di ' + minValue);
        question.value>maxValue && (message='Il valore inserito dev\'essere minore di ' + maxValue);

        return(
            message.length > 0 &&
            <div className="flex light" style={{width: '100%', paddingTop: '5px'}}>
                <h6>{message}</h6>
            </div>
        )
    }

    return(
        <>
            <div className={"flex y-a question-number " + active + waiting}  style={{boxSizing: 'boder-radius',  paddingRight: '15px', minHeight: '45px', width: 'auto', borderRadius: '5px', gap: '15px', paddingLeft: '15px'}}>
                <div>
                    <div  className="flex x-a y-a" style={{width: '30px', height: '30px', backgroundColor: 'var(--color5)', borderRadius: '100%'}}>
                        <img className="tick_icon" src={tick_icon} style={{width: '70%', filter: 'invert(1)'}}></img>
                    </div>
                </div>
                <div style={{width: '100%'}}>
                    <input type="number" value={(parseInt(question.value) !== 0 ? question.value : '0')} placeholder={option.title} className="bold" onInput={(e) => dispatch(select(question.id, option.cod, (e.target.value !== '' ? e.target.value : 0),  user.id, table))}></input>
                </div>
            </div>
            <ErrorMessage 
            error={error}
            min={min}
            max={max}/>
        </>
    )
}