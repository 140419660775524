import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { createStore,} from 'redux';
import rootReducer from './reducers/index';
import { Provider } from 'react-redux';
import ScrollToTop from './config/functions/scrolltotop';
import FusionCharts from 'fusioncharts';

const store = createStore(rootReducer)

FusionCharts.options.license({ key: 'ThB4d1E-13sA1C4A1A3A7B7D7B4B2F5F3E4qc1jA7A3okgC-7hC11A5cufF3F3I2B3B9B6B5C2B4B3H3G3I3C11A5C7qhhD4F1H3hD7E6F4A-9A-8kD2I3B6uqC2A1E2tllB3B7D1A3F3H3A2A15A32B13B9ccC-11G4H3D3H1A2B2B1B1D6F5F1A3D3C5j==', creditLabel: false })
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Provider store={store}>
        <ScrollToTop 
        containter='chart-body'/>
        <App />
      </Provider>
      
    </BrowserRouter>
);


reportWebVitals();
