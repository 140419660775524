import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteOption, select, updateOption } from "../../actions/survey";
import { showNotification } from "../../../../actions";
import tick_icon from '../../../../media/icons/tick.png';


export default function Option(props) {

    const dispatch = useDispatch();
    const params = useParams();

    const question = props.question;
    const option = props.option;
    const user = props.user;
    const table = props.table;

    const active = option.status === 'selected' ? 'active' : '';

    return(
        <div className="flex" style={{width: '100%'}}>
        <div className={"flex y-a question " + active}  style={{boxSizing: 'boder-radius', width: '100%', paddingRight: '15px', minHeight: '45px', borderRadius: '5px', gap: '15px', paddingLeft: '15px'}} onClick={() => {dispatch(select(question.id, option.cod, '', user.id, table)); dispatch(showNotification())}}>
            <div>
                <div className="flex x-a y-a" style={{width: '30px', height: '30px', backgroundColor: 'var(--color5)', borderRadius: '100%'}}>
                    <img className="tick_icon" src={tick_icon} style={{width: '70%', filter: 'invert(1)'}}></img>
                </div>
            </div>
            <div>
                {/* <h5 className="bold">{option.title}</h5> */}
                {
                    sessionStorage.getItem('role') === 'admin' ?
                <input value={option.title} className="bold" style={{border: 'none', background: 'none', paddingTop: '8px'}} onInput={(e) => dispatch(updateOption(question.id, option.cod, e.target.value))}></input>
                :
                <h4 className="bold">Si</h4>
                }
            </div>

        </div>
        {
            sessionStorage.getItem('role') === 'admin' && 
            <div className="flex y-a" style={{width: '40px', justifyContent: 'flex-end'}} onClick={() => dispatch(deleteOption(question.id, option.cod))}>
                <h4 className="light survey-btn">X</h4>
            </div>
        }
        </div>
    )
}