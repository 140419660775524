import React from 'react';

import Logo from '../../navbar/logo';

import { Link, useParams } from 'react-router-dom';

import icon from '../../../media/icons/people.svg';

function Menu() {

    const params = useParams();

    const pages = [
        {id: 'patients', title: 'Patients'},
        {id: 'doctors', title: 'Doctors'},
        {id: 'hospitals', title: 'Hospitals'},
        {id: 'studies', title: 'Studies'},
        {id: 'physician', title: 'Profile'}
    ]

    return(
        <div className="flex-column x-a pointer" style={{boxSizing: 'border-box', width: '100%', paddingLeft: '25px', paddingRight: '25px', gap: '18px'}}>
                <h3 className={"flex y-a color-white"} style={{}}>Menu</h3> 
                <div className="flex-column" style={{gap: '10px'}}>
                {
                    pages.map((page, index) => {
                        return(
                            <Link to={"/" + page.id} key={index}>
                            <div className="flex light" style={{gap: '15px'}}>
                                <img src={icon} style={{width: '25px'}}></img>
                                <h3 style={{color: 'white'}}>{page.title}</h3>
                            </div>
                            </Link>
                        )
                    })
                }
                </div>
        </div>
    )
}

function Navbar() {
        return (
                <div className="flex-column fixed y-a" style={{height: '100vh', width: 'var(--navbar--width)', backgroundColor: 'var(--color2)', gap: '30px', paddingTop: '15px'}}>
                        <Logo />  
                        <Menu />
                </div>
        )
}

export default Navbar;