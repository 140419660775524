const initialState = {
    username: '',
    status: '400',
    message: '',
}

function login(token) {
    localStorage.setItem('token', token)
    window.location.href = "/home";
}

function logout() {
    localStorage.removeItem('token')
    window.location.href = "/login";
}

const Login = (state = initialState, action) => {

    
    switch(action.type) {
        case 'login': 

            switch(action.status.status) {

                case '210':
                    login(action.status.token);
                    return state = action.status;
                case '400':
                    return state = action.status;
                case '401':
                    return state = action.status;
                default:
                    return;
            }

        case 'logout': 
            logout();
            return state = initialState;

        default:
            return state
    }
}

export default Login;