import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFilter, pageFilter, saveFilters, deleteGroup } from '../../actions';
import { deleteFilters } from '../../actions/pages';
import search_icon from '../../media/icons/search.svg';
import tick_icon from '../../media/icons/tick.png'; 
import { useNodes } from '../../config/charts/useNodes';
import { t } from '../../config/functions/translate';
import MultiRangeSlider from '../general/rangeslider';



function toggleClass(e, classe) {
    if (e.currentTarget.className.includes(classe)) {
        e.currentTarget.classList.remove(classe)
    }else{
        e.currentTarget.classList.add(classe)
    }
}

function Tools(props) {

    const page = props.page;

    const chart = props.chart;

    const globalFilters = useSelector(state => state.globalfilters);

    
    const dispatch = useDispatch();

    const tools = [
        {title: 'Disattiva tutti i filtri', icon: '', function_value: ''}
    ]
    
    return(
       tools?.map((tool, index) => {
            return(
                        Object?.keys(globalFilters)?.length !== 0 ? 

                        <div className="pointer" style={{width: 'auto', whiteSpace: 'nowrap', marginTop: '10px'}} onClick={() => dispatch(deleteFilters())} key={index}>
                            <h5 className="bold light">{t(tool.title)}</h5>
                        </div>

                        :

                        ''
            )
       })
    )
}

function Option(props) {

    const options = Object.keys(props.options);
    const filters = props.filters;
    const globalFilters = useSelector(state => state.globalfilters);
    const filter = props.filter;
    const options2 =  filter !== '' ? (options.filter(option => props.options[option].includes(filter))) : options
    const template = props.template;
    const chart = props.chart;
    const page = props.page;


    const dispatch = useDispatch();

    function updateFilters(e, option, template, page, chart) {

            if (template === undefined || page === undefined || chart === undefined) {
                dispatch(pageFilter(props.column, option))
            }else{
                dispatch(saveFilters(page?.page_id, chart?.id, props.column, option, template))
            }
    }

    return(
            <div className="flex-column" style={{gap: '0px'}}>
                {
                    options2?.map((option, index) => {
                        const chart_filters = chart?.filters === undefined ? {} : chart.filters; 

                        const active = Object.keys(chart_filters).length === 0 ? (globalFilters[props.column]?.includes(option) ? ' active' : '') : (chart_filters[props.column]?.includes(option) ? ' active' : '');

                        return(
                            <div className="flex-column" style={{flexWrap: 'wrap', gap: '0px'}} key={index}>    
                                <div className={"option flex y-a pointer " + active} style={{position: 'relative', gap: '5px'}} onClick={(e) => updateFilters(e, option, template, page, chart)} key={index}>
                                    <div className="flex y-a" style={{width: '35px', height: '38px'}}>
                                        <div className="flex x-a y-a box pointer" style={{borderRadius: '4px', width: '25px', height: '25px'}}>
                                            <img className="tick-icon" src={tick_icon} style={{width: '80%', height: 'auto'}}></img>
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="pointer " key={index}>{t(props.options[option])}</h5>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    
                }
                
            </div>
    )

}

function Filter(props) {

    const title = props.obj.title;
    const description = props.obj.description;
    const db_column = props.obj.column;
    const options = props.obj.nodes
    const filters = props.filters;

    const globalFilters = useSelector(state => state.globalfilters);

    const template = props.template;
    const chart = props.chart;
    const page = props.page;

    const active = globalFilters[db_column]?.length !== undefined ? 'active' : '';
    
    const dispatch = useDispatch();

    // props.index === 0 ? 'flex' : 'none'
    const [visibility, setVisibility] = useState('none');

    const [desc, setDescription] = useState('none');

    function toggleVisibility(parameter) {
        if (visibility !== 'flex') {
            setVisibility('flex')
        }else{
            setVisibility('none')
        }
    }

    const [filter, setFilter] = useState('');
    function toggleDescription() {
        if (desc !== 'none') {
            setDescription('none')
        }else{
            setDescription('active')
        }
    }

    return(
        <div className="flex-column filter pointer" style={{width: '100%', gap: '8px', marginBottom: '24px'}} >
                    <div className="flex" style={{width: 'auto'}}>
                        <div className="flex border-box pointer" style={{width: '100%', paddingLeft: '0px', gap: '8px', opacity: (filters[db_column] === undefined & visibility !== 'flex'  ? '0.55' : '1')}} onClick={() => toggleVisibility('flex')}>
                            <h4 className="bold user-select">{t(title)}</h4>
                            <h5 className="bold color-color4">
                                {filters[db_column]?.length > 0 && '· ' + filters[db_column]?.length}
                            </h5>
                        </div>
                        
                        <div className="flex" style={{width: 'auto'}}>
                            {active !== '' &&
                                <button className="info light pointer" onClick={() => dispatch(deleteGroup(db_column))} style={{width: '20px'}}>x</button>
                            }
                            {
                            description !== '' &&
                                <button className="info light pointer" onClick={() => toggleDescription()} style={{width: '20px'}}>i</button>
                            }
                            <div className="info flex x-a y-a" onClick={() => toggleVisibility('flex')} style={{width: '15px', paddingLeft: '6px', paddingRight: '6px'}}>
                                <h3 className="light pointer" >{visibility === 'flex' ? '-' : '+'}</h3>
                            </div>
                        </div>
                    </div>
                        {
                            description !== '' &&
                            <div className="light" style={{paddingTop: '0px', paddingBottom: '5px'}}>
                                <h5>{description}</h5>
                            </div>
                        }
                    <div style={{display: visibility, flexDirection: 'column', gap: '8px'}}>
                        {/* <div className="flex pointer border-box" style={{width: '100%', marginTop: '8px', gap: '5px', paddingLeft: '30px', paddingRight: '12px', backgroundColor: '#F1F1F1', height: '45px', borderRadius: '10px'}}>
                            <img src={search_icon} style={{width: '23px', height: 'auto', opacity: '0.55', marginRight: '13px'}}></img>
                            <input className="search-filter" style={{padding: '0', height: '40px', border: 'none', background: 'none'}} placeholder="Search anything.." onInput={(e) => setFilter(e.target.value)}></input>
                        </div> */}

                            {
                                (db_column === 'eta' || db_column === 'annoprimadiagnosi') ?
                                <MultiRangeSlider 
                                min={Object.keys(options)[0]}
                                max={Object.keys(options)[1]}
                                options={options}
                                onChange={({ min, max }) => console.log()}
                                db_column={db_column}
                                chart={chart}
                                template={template}/>

                                :
                                
                                <Option 
                                column={db_column}
                                options={options}
                                filter={filter}
                                filters={props.filters}
                                template={template}
                                chart={chart}
                                page={page}/>
                            }
                    
                    </div>
                </div>
    )
}

export default function Filters(props) {

    const module = useSelector(state => state.window);

    const template = props.template;
    const chart = props.chart;
    const page = props.page;

    const activeFilters = (chart?.filters !== undefined ? chart?.filters : {});


    useNodes();

    const nodes = useSelector(state => state.nodes);

    const groups = nodes?.reduce((x, y) => {
    
        (x[y.group] = x[y.group] || []).push(y);

        return x;

    }, {})

                // const groups = data?.reduce((x, y) => {

                //     (x[y.group] = x[y.group] || []).push(y);
            
                //     return x;
            
                // }, {})
    const keys = Object.keys(groups).sort();

    const width = '450px';

    const [group = keys[0], setGroup] = useState();

    function Groups(props) {

        return(
            <div className="flex pointer" style={{marginTop: '10px',  gap: '5px', flexWrap: 'wrap'}}>

                {keys?.map((filter, index) => {
                    const active = (filter === group ? 'active' : ''); 
                    return(
                    <h3 className={"filter-group " + active} style={{lineHeight: '15px'}} onClick={() => setGroup(filter)} key={index}>{t(filter)}</h3>
                    )
                })}
            <div style={{width: '100%', height: '1.5px', backgroundColor: 'var(--border--color)'}}></div>
            </div>
        )

    }

    //


    return(
        // <div className={"sidebar " + (sidebar_visibility !== 'none' ? 'active' : '') + " absolute"} style={{backgroundColor: 'white', borderLeft: '1px solid var(--border--color)', right: '0', width: width, height: '100%',  zIndex: '10', paddingTop: 'var(--header--height)', zIndex: '80'}}>
            <div style={{overflow: 'auto', width: '100%', paddingTop: '5px'}}>
                <div className="flex-column" style={{width: '100%', paddingBottom: '18px'}}>

                <Groups />
                
                <Tools 
                filters={activeFilters}
                page={page}
                chart={chart}/>

                </div>
                
                {

                    keys?.map((key, index) => {

                        if (group === key) {
                        return(
                            
                            <div key={index}>
                            
                            {groups[key]?.sort().map((node, index) => {
                                return(
                                    <Filter
                                    key={index}
                                    index={index}
                                    obj={node}
                                    filters={activeFilters}
                                    template={template}
                                    page={page}
                                    chart={chart}/>
                                )
                            })}
                            </div>
                        )
                    }
                    })
                    
                    
                }

            </div>
        // </div>        
    )
};