import { uniqueId } from "../functions/uniqueid";

const initialState = {
    data: [{}],
    columns: [],
    main: 'name',
    sorting: {
        direction: '',
        field: ''
    },
    hidden_sections: [],
    sidebar: {
        visibility: 'none'
    },
    group: '',
    pagination: {
        divider: 100,
        page: 1
    },
    options: [],
    module: {
        visibility: 'none',
        type: '',
        data: {},
        top: '',
        left: ''
    }
};

function getDate() {
    let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

function createRecord(survey, docid, pid, date) {
    // fetch 

    const url = "https://setup.sonardigital.it/endpoints/survey/create.php";
    fetch(url, {
      method: 'POST',
      heders: {
        'Accept': 'application/json',
        'Content-type':'application/json'
      },
      body: JSON.stringify({
        table: 'psoriasi',
        docid: docid,
        pid: pid,
        date: date
    })
    }).then(response => {
      return response.json();
      }).then(data => {
        window.location.href = "/survey/" + pid;
      }).catch(error => {
        console.log("Error: " + error);
      })

}

const Database = (state = initialState, action) => {
    switch(action.type) {
        case 'databaseData':

            state = initialState;

            state.data = action.data;
            state.main = Object.keys(action.data[0])[0]

            
            state.columns = Object?.keys(action.data[1]);
            
            return state = {...state};

        case 'sort':

            if (state.sorting.field === action.obj) {
                state.sorting.direction = (state.sorting.direction === 'DESC') ? state.sorting.direction = '' : 'DESC';
            }else{
                state.sorting.field = action.obj;
                state.sorting.direction = '';
            }

            // state.main = action.obj;
            
            return state = {...state};

        case 'changeTablePage':

            state.pagination.page = action.page;

            // state.main = action.obj;
            
            return state = {...state};
            
        case 'addRecord':

            let unique_id = uniqueId();
            let docid = '01';
            let date = getDate();
            // createRecord('survey', docid, unique_id, date)

            // state.data = [...state.data, {id: '', docid: docid, pid: unique_id, 'data di aggiunta': date}]
            state.hidden_sections = state.hidden_sections.filter(section => section !== action.section)
            state.data = [...state.data, {id: '', [state.group]: action.section}]

            return state = {...state}
        case 'toggleColumn':

            if (state.columns.includes(action.column)) {
                state.columns = state.columns.filter(column => column !== action.column);
            }else{
                state.columns = [...state.columns, action.column];
            }

            return state = {...state};
        case 'toggleGroup':

            if (state.group === action.group) {
                state.group = '';
            }else{
                state.group = action.group;
            }

            return state = {...state};
        case 'toggleSection':

            if (state.hidden_sections.includes(action.section)) {
                state.hidden_sections = state.hidden_sections.filter(section => section !== action.section)
            }else{
                state.hidden_sections = [...state.hidden_sections, action.section];
            }
            
            return state = {...state};
        case 'databaseSidebar':

            state.module = {
                visibility: 'none',
                type: 'module',
                data: {},
                top: '',
                left: ''
            }

            if (state.sidebar.visibility === 'visible') {
                state.sidebar.visibility = 'none';
            }else{
                state.sidebar.visibility = 'visible';
            }

            return state = {...state};
        case 'changeTableDivider':

            state.pagination.divider = action.divider;

            return state = {...state};
        case 'toggleModule':

            state.sidebar.visibility = 'none';

            let visibility;

            if (Object.keys(action.row).length > 0) {
                visibility = 'visible';
            }else{
                visibility = state.module.visibility === 'visible' ? 'none' : 'visible';
            }
            
            state.module = {
                visibility: visibility,
                type: 'module',
                data: action.row,
                top: '',
                left: ''
            }

            return state = {...state};
        case 'options':

            //Lista di opzioni nello store
            state.module = {
                visibility: 'visible',
                type: 'options',
                top: action.top,
                left: action.left
            }
            
            state.options = action.options;

            return state = {...state};

        default:
            return state
    }
}

export default Database;