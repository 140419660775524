import column_icon from '../../media/icons/column.svg';
import bar_icon from '../../media/icons/bar.svg';
import pie_icon from '../../media/icons/pie.svg';
import donut_icon from '../../media/icons/donut.svg';

export function getTypes() {

    return [
        {name: 'bar2d', icon: bar_icon, title: ''}, 
        {name: 'column2d', icon: column_icon, title: ''}, 
        {name: 'pie2d', icon: pie_icon, title: ''}, 
        {name: 'doughnut2d', icon: donut_icon, title: ''}
    ];

}