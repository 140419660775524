import Database from '../../components/table/database';

export default function Doctors() {

    return(
        <Database 
        title='Doctors'
        sub_title='Database dottori'
        url='https://setup.therapyknowledge.com/endpoints/physicians/read.php'
        body={JSON.stringify({
            token: localStorage.getItem('token')
        })}/>
    )
}