export function t(word) {

    const language = localStorage.getItem('language');

    const words = [
        //Parole
        {cod: '', it: 'Tipo di ospitalità', en: 'Hospitality'},
        {cod: '', it: 'Cerca nella dashboard', en: 'Search in the dashboard'},
        {cod: '', it: 'Pazienti', en: 'Patients'},
        {cod: '', it: 'Medici', en: 'Physicians'},
        {cod: '', it: 'Grafici', en: 'Charts'},

        //Menu
        {cod: '', it: 'Campione', en: 'Sample'},
        {cod: '', it: 'Profilo medici', en: 'Physician profile'},
        {cod: '', it: 'Pazienti - Info generali', en: 'Patients - General info'},
        {cod: '', it: 'Stato clinico attuale', en: 'Current clinical status'},
        {cod: '', it: 'Terapia corrente', en: 'Current therapy'},
        {cod: '', it: 'Terapia precedente', en: 'Previous therapy'},

        //Descrizioni
        {cod: '', it: 'Campione pazienti', en: 'Patients sample'},
        {cod: '', it: 'Benvenuti in Therapy Knowledge: un sistema epidemiologico internazionale che monitora i trattamenti medici. Collezioniamo un alto volume di dati clinici e reports in modo costante permettendo una valutazione in tempo reale dei trattamenti correnti.', en: 'Welcome to Therapy Knowledge: an international epidemiological tool to monitor the treatment of MF-SS.This is the only research tool of its kind that collects a high volume of patient data and reports on such a regular basis, allowing for a real-time evaluation of current treatment.'},
        {cod: '', it: 'Dati inseriti incorretti', en: 'Incorrect username or password'},
        {cod: '', it: 'Login effettuato correttamente', en: 'Logged successfully'},

        //Filtri
        {cod: '', it: 'Demografia', en: 'Demography'},
        {cod: '', it: 'Genere', en: 'Genere'},
    ]

    let translation;
    
    if (word !== '') {
        translation = words.find(obj => (obj?.it?.toLowerCase() === word?.toLowerCase() || obj?.en?.toLowerCase() === word?.toLowerCase()));
        if (translation === undefined || translation === 'undefined'){
            return word;
        }
        return translation[language];
    }else{
        return word;
    }


}