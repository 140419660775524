export function t(word) {

    let language = localStorage.getItem('language');
    language = 'it';


    const words = [
        {cod: 'S1', it: 'Scelta multipla', en: 'Name'},
        {cod: 'S2', it: 'Scelta singola', en: 'Name'},
        {cod: 'S3', it: 'Campo numerico', en: 'Name'},
        
    ]

    let translation;
    
    if (word !== '') {
        translation = words.find(obj => (obj?.it.toLowerCase() === word.toLowerCase() || obj?.en.toLowerCase() === word.toLowerCase() || obj?.cod.toLowerCase() === word.toLowerCase()));
        if (translation === undefined || translation === 'undefined'){
            return word;
        }
        return translation[language];
    }else{
        return word;
    }


}