import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { log } from "../actions/login";

import image from '../media/icons/background.png';

import { t } from "../config/functions/translate";
import { Link } from "react-router-dom";

export default function Login() {

    const status = useSelector(state => state.login);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    function auth(e) {

        if (e.key !== 'Enter' && e.type !== 'click') {
            return;
        }
        
        if (username !== '' && password !== '') {

            const body = new FormData();
            body.append('username', username);
            body.append('password', password);
    
            // const url = 'https://api.sonardigital.it//format/format.php?api=login';
            const url = 'https://setup.sonardigital.it/endpoints/login/read.php';
    
            fetch(url, {
                method: 'POST',
                heders: {
                'Accept': 'application/json',
                'Content-type':'application/json'
                },
                body: JSON.stringify({
                    "username": username,
                    "password": password
                })
            }).then(response => {
                return response.json();
                }).then(status => {
                    console.log(status)
                dispatch(log('login', status));
                }).catch(error => {
                console.log("Error: " + error);
                })
    
        }else{
    
                dispatch(log('login', {
                    status: '400',
                    message: 'Inserire i dati mancanti'
                }));
            
        }
    
    }

    return(
       <div className="flex x-a y-a" style={{position: 'fixed', top: '0', width: '100vw', height: '100vh'}}>
            <div className="flex x-a y-a" style={{width: '50%', height: '100%', backgroundColor: 'var(--color2)', backgroundImage: "url('" + image + "')", backgroundPosition: 'center', backgroundSize: 'cover'}}>
                
            </div>
            <div className="flex-column x-a y-a" style={{minWidth: '550px', width: '50%', height: '100%', gap: '15px'}}>
                <div className="flex-column x-a y-a border-box" style={{width: '450px', height: '480px', border: '1px solid var(--border--color)', borderRadius: '25px', paddingLeft: '30px', paddingRight: '30px'}}>
                    <div className="" style={{height: '85%', width: '100%'}}>
                        <div className="flex-column x-a y-a" style={{height: '45%', width: '100%', gap: '10px'}}>
                            <h3 className="user-select" style={{fontSize: '22px'}}>Accedi</h3>
                            <h5 className="user-select light" style={{textAlign: 'center'}}>
                            {t('Benvenuti in Therapy Knowledge: un sistema epidemiologico internazionale che monitora i trattamenti medici. Collezioniamo un alto volume di dati clinici e reports in modo costante permettendo una valutazione in tempo reale dei trattamenti correnti')}.</h5>
                        </div>
                        <div className="flex-column" style={{height: '55%', width: '100%', gap: '15px'}}>
                            <div>
                                <h4 className="bold user-select">Username</h4>
                                <input className="light" value={username} onInput={(e) => setUsername(e.target.value)} onKeyDown={(e) => auth(e)}></input>
                            </div>
                            <div>
                                <h4 className="bold user-select">Password</h4>
                                <input className="light" value={password} onInput={(e) => setPassword(e.target.value)} onKeyDown={(e) => auth(e)} type="password"></input>
                            </div>
                            <div>
                                <button className="login-btn pointer" onClick={(e) => auth(e)} onKeyDown={(e) => auth(e)}>Login</button>
                            </div>
                            <div className="flex-column x-a y-a" style={{gap: '2px'}}>
                            {/* <h4 className="light pointer">{t('Recupera accesso')}</h4> */}
                            <h4>{t(status.message)}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="mailto: info@therapyknowledge.com">
                    <h4 className="light pointer">Recupera accesso</h4>
                </a>
            </div>
       </div>
    )
}