import Header from "../components/layout/header";
import Sidebar from "../components/layout/sidebar";
import Body from "../components/layout/body";
import { usePage } from "../config/functions/usepage";
import Navbar from "../components/layout/navbar";
import { useDispatch, useSelector } from "react-redux";

import Setup from "../components/layout/setup";
import { toggleSidebar } from "../actions";
import { addChart, deleteFilters } from "../actions/pages";
import { useEffect } from "react";

function Layout(){

    const page = usePage();

    const pageInfo = {
        title: page?.title,
        date: '22/05/2022',
        sub_title: page?.subtitle,
        page: page,
        tools: {
            sidebar: toggleSidebar,
            add: addChart
        }
    }

    const module = useSelector(state => state.window);

    const sidebar = useSelector(state => state.sidebar);

    const dispatch = useDispatch();

    return(
        <>
        <Navbar />

            <div className="flex absolute border-box " style={{boxSizing: 'border-box', left: 'var(--navbar--width)', width: 'calc(100% - var(--navbar--width))', height: '100%'}}>

            <Header 
            info={pageInfo}/>
            
            <div className="flex fixed" style={{width: 'calc(100% - var(--navbar--width))', height: '100%'}}>
               
                    <Body
                    url='https://setup.therapyknowledge.com/endpoints/charts/read.php'
                    page={page}/>

                    {
                        sidebar === 'block' &&
                        <div style={{width: '100%', height: '100%', backgroundColor: 'var(--color2)',  opacity: '0.8', position: 'absolute', top: '0', left: '0'}} onClick={() => dispatch(toggleSidebar())}>
                        </div>
                    }
                    

                    <Sidebar />

            
            </div>

            
            </div>
            {
                module.visibility !== 'none' &&
                <Setup />
            }

    </>
    )
}

export default Layout;