import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { nodes } from "../../actions";

export function useNodes() {

    const dispatch = useDispatch();

    const nodeList = useSelector(state => state.nodes);

    const url = "https://api.sonardigital.it/format/format.php?api=nodes";

    useEffect(() => {
        
        if (nodeList.length == 0) {

            fetch(url).then(response => {
            return response.json();
            }).then(data => {

                data.map(data => data.visibility = 'none')
                
                dispatch(nodes(data));

            }).catch(error => {
            console.log("Error: " + error);
            })
        }
            
    }, [])

}