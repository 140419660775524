import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";

import { addPages } from '../../actions/pages.js';

import { window } from '../../actions/window.js';

import { t } from '../../config/functions/translate.js';

export default function Menu() {

    const params = useParams();

    const menu = useSelector(state => state.pages);

    const dispatch = useDispatch();

    const path = '/dashboard/';

    const groups = menu?.filter(page => page.link !== '')?.reduce((x, y) => {

        (x[y.page_group] = x[y.page_group] || []).push(y);

        return x;

    }, {});

    const keys = Object.keys(groups);
    
    let currentPage = menu.find(page => page.page_id === params.id);

    currentPage = currentPage === undefined ? menu.find(page => page.page_id === 'home') : currentPage;

    const dashboard = menu?.filter(page => page.link === '');
    
    return (
        <div className="flex-column y-a d-w100 m-hide user-select pointer" style={{boxSizing: 'border-box', gap: '3px', overflowY: 'scroll', paddingBottom: '150px'}}>
        <div className="flex y-a" style={{width: '90%', height: 'auto'}}>
            <h3 className={"flex y-a color-white"} style={{width: '90%', minHeight: '15px', padding: '8px', cursor: 'default'}}>{t('Insights')}</h3> 
            {/* <h4 onClick={() => dispatch(addPages(currentPage.group))} style={{color: 'white', width: '30px', height: '20px'}}>+</h4>    */}
        </div>
        {
            !menu.length > 0 &&
            <div className="flex-column pointer" style={{width: '90%', gap: '5px'}}>
                <div className="menu inactive" style={{width: '100%', backgroundColor: '#4c5165', height: '35px', borderRadius: '5px'}}>
                </div>
                <div className="menu inactive" style={{width: '100%', backgroundColor: '#4c5165', height: '35px', borderRadius: '5px'}}>
                </div>
                <div className="menu inactive" style={{width: '100%', backgroundColor: '#4c5165', height: '35px', borderRadius: '5px'}}>
                </div>
                <div className="menu inactive" style={{width: '100%', backgroundColor: '#4c5165', height: '35px', borderRadius: '5px'}}>
                </div>
                <div className="menu inactive" style={{width: '100%', backgroundColor: '#4c5165', height: '35px', borderRadius: '5px'}}>
                </div>
            </div>
        }
{/* 
        <Link to="/database" draggable={false} className="" style={{width: '90%', height: 'auto', marginBottom: '3px'}}>
                <h3 className={"flex y-a color-white page "} style={{minHeight: '10px', height: '20px', padding: '8px', lineHeight: '20px', fontSize: '18px', borderRadius: '5px'}}>Database</h3>
        </Link> */}
       
        {
            keys?.map((key, index) => {
                return(
                    groups[key]?.map((page, index) => {
                        if (index === 0) {
                            return(
                                <Link to={path + page.page_id} draggable={false} className="" key={index} style={{width: '90%', height: 'auto', marginBottom: '1px'}}>
                                    <h3 className={"flex y-a color-white page " + ((currentPage?.page_group === key) ? 'active' : '')} style={{minHeight: '10px', height: '20px', padding: '8px', lineHeight: '20px', fontSize: '18px', borderRadius: '5px'}}>{t(page.title)}</h3>
                                </Link>
                            )
                        }else{
                            if (currentPage?.page_group === key) {
                                return (
                                    <Link to={path + page.page_id} draggable={false} className="flex y-a border-box" key={index} style={{position: 'relative', width: '100%', height: 'auto', paddingLeft: '40px', paddingTop: '0px', paddingBottom: '0px'}}>
                                        <div className="flex x-a y-a" style={{width: '10%', height: '100%'}}>
                                            {
                                                page.page_id === params.id && 
                                                <div style={{width: '13px', height: '13px', backgroundColor: 'var(--page--active)', borderRadius: '50px', opacity: '1'}}></div>
                                            }
                                        </div>
                                        <div style={{width: '90%'}}>
                                            <h5 className="flex bold y-a color-white group-page" style={{width: '90%', padding: '4px', fontSize: '17px', borderRadius: '8px', opacity: (currentPage.id === page.id ? '1' : '0.30')}}>{t(page.title)}</h5>
                                        </div>
                                    </Link>
                                )
                            }
                        }
                        
                })
                )
            })
            
        }

            <div className="flex y-a" style={{width: '90%', height: 'auto', marginTop: '20px'}}>
                <div style={{width: '100%', height: '100%'}}>
                    <h3 className={"flex y-a color-white"} style={{width: '90%', minHeight: '15px', padding: '8px',  cursor: 'default'}}>{t('Dashboards')}</h3> 
                </div>
                <div className='flex y-a' style={{position: 'relative', width: '30%', height: '100%', justifyContent: 'end'}}>
                    <h3 className="navbar-btn" style={{color: 'white'}} onClick={() => dispatch(addPages('dashboard'))}>+</h3>
                </div>
            </div>
        {
            dashboard?.map((page, index) => {

                        return(
                            <Link to={'/my-dashboard/' + page.page_id} draggable={false} className="" key={index} style={{width: '90%', height: 'auto', marginBottom: '3px'}}>
                                <h3 className={"flex y-a color-white page " + ((currentPage?.page_id === page?.page_id) ? 'active' : '')} style={{minHeight: '10px', height: '20px', padding: '8px', lineHeight: '20px', fontSize: '18px', borderRadius: '5px'}}>{t(page.title)}</h3>
                            </Link>
                        )
                })
        }
        
        </div>
    )
}