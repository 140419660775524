import map_image from '../../media/icons/Mappa Italia.png';

export default function Map() {

    const regions = [
        {title: 'Nord Ovest', color: '#05B7E0'},
        {title: 'Nord Est', color: '#4BCD05'},
        {title: 'Centro', color: '#F69A11'},
        {title: 'Sud', color: '#CC1C0A'}
    ]

    return(
        <div className="flex" style={{gridColumn: 'span 6', border: '1px solid var(--border--color)', borderRadius: 'var(--widget--radius)'}}>
                    <div className="flex" style={{width: '100%', height: '100%', transform: 'scale(0.9)'}}>
                        <div className="flex x-a y-a" style={{width: '72%', height: '100%'}}>
                        <img src={map_image} style={{width: '370px', opacity: 0.85}}></img>
                    </div>
                    <div className="flex-column x-a" style={{width: '26%', height: '100%', gap: '15px'}}>
                        {
                            regions.map((region, index) => {
                                return(
                                    <div className="flex" key={index}>
                                        <div style={{width: '40px'}}>
                                            <div style={{width: '25px', height: '25px', backgroundColor: region.color, borderRadius: '100px'}}></div>
                                        </div>

                                        <div className="flex y-a" style={{width: '70%'}}>
                                            <h4 className="bold">{region.title}</h4>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    </div>
                </div>
    )
}